<template>
    <div class="bulk-uploads" ref="bulkUploads">
        <div class="file-items" v-if="fileItems.length > 0">
            <file
                v-for="file of fileItems"
                :file="file"
                :key="file.id"
                :readonly="readonly"
                @deleteFile="deleteFile"
                @resetFile="resetFile"
            ></file>
        </div>
        <div
            v-if="!readonly"
            class="upload-contain"
            :class="{ dragging }"
            @dragover.prevent
            @dragenter="dragenter"
            @dragleave="dragleave"
            @drop.prevent="drop"
        >
            <template v-if="dragging">
                {{ $i18n('bulkUploads.drag.first') }}
            </template>
            <template v-else>
                <template v-if="!shortWord">{{ $i18n('bulkUploads.drag.second') }}</template>
                <span @click.stop="chooseFiles"></span>
                {{ $i18n('bulkUploads.drag.third') }}
            </template>
        </div>
        <input v-show="false" type="file" ref="inputFile" @change="handleChange" />
    </div>
</template>

<script>
import file from './file.vue';
import BaseField from '../../baseField/baseField.vue';
import Gikam from '../../../../core/gikam-core';
import { ajaxSuccess } from '@/gikam/js/core/gikam-global-event';

export default {
    name: 'bulkUploadsField',

    extends: BaseField,

    components: { file },

    props: ['options', 'validateArg'],

    data() {
        return {
            dbTable: this.options.dbTable,
            bizCategory: this.options.bizCategory,
            scope: this.options.scope,
            fileItems: [],
            dragging: false,
            server: this.options.server ?? `${Gikam.IFM_CONTEXT}/core/module/item/files`,
            readonly: this.options.readonly,
            multiple: this.options.multiple ?? true,
            accept: this.options.accept,
            shortWord: true
        };
    },

    mounted() {
        const resizeObserver = new ResizeObserver(entries => {
            const contentRect = entries[0].contentRect;
            if (contentRect.width > 280) {
                this.shortWord = false;
            } else {
                this.shortWord = true;
            }
        });

        resizeObserver.observe(this.$refs.bulkUploads);
    },

    created() {
        this.getFiles();
    },

    computed: {
        bizId() {
            const bizIdField = this.options.bizIdField;
            if (bizIdField) {
                return this.validateArg?.[bizIdField];
            } else {
                return this.validateArg?.id;
            }
        }
    },

    methods: {
        dragenter() {
            this.dragging = true;
        },

        dragleave() {
            this.dragging = false;
        },

        drop(event) {
            this.dragging = false;
            const files = event.dataTransfer.files;
            this.uploadFiles(files);
        },

        chooseFiles() {
            const inputFile = this.$refs.inputFile;
            this.multiple && inputFile.setAttribute('multiple', 'multiple');
            this.accept && inputFile.setAttribute('accept', this.getFileType(this.accept));
            inputFile.click();
        },

        handleChange(event) {
            const files = event.target.files;
            this.uploadFiles(files);
        },

        uploadFiles(files) {
            if (!this.multiple && files.length > 1) {
                return Gikam.toast(this.$i18n('bulkUploads.file.single'));
            }
            const uploadingFiles = this.fileItems.filter(item => item.state === 'uploading');
            if (uploadingFiles.length > 0) {
                if (!this.multiple) {
                    return Gikam.toast(this.$i18n('bulkUploads.file.single'));
                }

                // 再次添加文件时，如果存在正在下载的，先将其添加到队列
                this.addFileItems(files);
                return;
            }

            this.addFileItems(files);
            const uploadFiles = this.fileItems.filter(item => item.state === 'start');
            this.uploadNewFiles(uploadFiles);
        },

        uploadNewFiles(uploadFiles) {
            if (uploadFiles.length === 0) {
                this.getFiles();
                return;
            }

            const uploadPromise = [];
            uploadFiles.forEach(file => {
                uploadPromise.push(this.uploadFileToServe(file));
            });

            Promise.all(uploadPromise).then(() => {
                const uploadFiles = this.fileItems.filter(item => item.state === 'start');
                this.uploadNewFiles(uploadFiles);
            });
        },

        addFileItems(files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                this.fileItems.push({
                    id: Date.now() + i,
                    file: file,
                    targetId: this.dbTable + '$' + this.bizId,
                    name: file.name,
                    size: (file.size / 1024).toFixed(2),
                    bizCategory: this.bizCategory,
                    process: 0,
                    state: 'start',
                    errorMessage: '',
                    isFrontValidate: false
                });
            }
        },

        uploadFileToServe(file) {
            file.state = 'uploading';
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                const form = new FormData();
                xhr.open('post', this.server);
                if (Gikam.isNotEmpty(localStorage.getItem('loginTenantId'))) {
                    xhr.setRequestHeader('sunway.tenant', localStorage.getItem('loginTenantId'));
                }

                form.append('file', file.file);
                form.append('targetId', file.targetId);
                form.append('name', file.name);
                file.bizCategory && form.append('bizCategory', file.bizCategory);
                this.scope && form.append('scope', this.scope);

                xhr.upload.onprogress = e => {
                    file.process = parseInt((e.loaded / e.total).toFixed(2) * 100);
                };

                xhr.onreadystatechange = () => {
                    if (xhr.readyState !== 4) {
                        return;
                    }
                    if (xhr.status === 200) {
                        if (ajaxSuccess(xhr.responseText) === false) {
                            file.state = 'fail';
                            return;
                        }

                        file.id = xhr.responseText;
                        file.state = 'success';
                        resolve(xhr.responseText);
                    } else {
                        file.state = 'fail';
                        const response = xhr.response;
                        if (response) {
                            file.errorMessage = Gikam.propI18N(JSON.parse(response).message);
                            reject(file.errorMessage);
                        } else {
                            file.errorMessage = this.$i18n('upload.tip.upload.failed');
                            reject(file.errorMessage);
                        }
                    }
                };

                xhr.send(form);
            });
        },

        getFiles() {
            const url = `${Gikam.IFM_CONTEXT}/core/module/item/files/queries`;
            Gikam.post(
                url,
                Gikam.getJsonWrapper({
                    f: { targetId: `${this.dbTable}$${this.bizId}` },
                    n: -1
                })
            ).done(({ rows }) => {
                this.fileItems = rows ?? [];
                if (!this.multiple && this.fileItems.length > 0) {
                    this.fileItems = [this.fileItems.pop()];
                }
            });
        },

        deleteFile(id) {
            this.fileItems = this.fileItems.filter(file => file.id !== id);
        },

        resetFile(file) {
            this.uploadFileToServe(file);
        },

        getFileType(type) {
            const mimeMapper = {
                image: 'image/*',
                audio: 'audio/*,video/*',
                pdf: 'application/pdf',
                xls: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                zip: 'aplication/zip',
                doc: 'application/msword',
                docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                txt: 'text/plain'
            };
            if (Array.isArray(type)) {
                return type.reduce((total, item) => {
                    return total + (mimeMapper[item] || '.' + item) + ',';
                }, '');
            } else {
                return mimeMapper[type] || '.' + type;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.bulk-uploads {
    width: 100%;
    height: 100% !important;
    padding: 4px 0 !important;
    .upload-contain {
        margin-top: 4px;
        height: 24px;
        border: 1px dashed rgba(217, 217, 217, 1);
        border-radius: 2px;
        font-size: 12px;
        color: #1a1a1a;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: default;

        &.dragging {
            background: #b7b7b7;
            border: 1px solid rgba(0, 121, 147, 1);
            border-radius: 2px;
            color: #ffffff;
            cursor: move;
        }

        span {
            width: 16px;
            height: 16px;
            background: url(./img/upload.svg) no-repeat;
            margin: 0 8px;
            cursor: pointer;
        }
    }
}
</style>
