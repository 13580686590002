<template>
    <div class="row-fixed-bottom" :style="{ height, width: containerWidth }">
        <scroll :scrollingY="false" :scrollingX="false" barSize="0" ref="rowFixed" :maxHeight="'100%'">
            <table :style="{ width, height }">
                <thead>
                    <template v-for="(item, index) in columns">
                        <th
                            :key="index"
                            :style="getCellWidth(item)"
                            v-if="getFieldVisible(item) && getFieldHideHeader(item)"
                            :colspan="item.colspan"
                        ></th>
                    </template>
                </thead>
                <tbody ref="row-fixed-body">
                    <tr
                        v-for="(row, rowIndex) in fixedData"
                        :key="row.id || rowIndex"
                        @click="rowActive(rowIndex)"
                        :data-index="rowIndex"
                        :row-group-index="row._groupIndex"
                        :parent-group-index="row._parentGroupIndex"
                        :class="{
                            expand: row._expand,
                            'row-group': row._groupRow,
                            hidden: getRowHidden(row),
                            hide: row.show === false,
                            active: rowActiveId === row.id
                        }"
                        :ref="'row-' + row.id"
                        :style="getRowStyle(row)"
                    >
                        <td
                            v-if="row._groupRow && options.group.groupCheckbox"
                            class="body-cell body-cell-group-checkbox"
                            :colspan="1"
                        >
                            <div v-if="columns[0].checkbox" class="body-cell select-row-checkbox-cell">
                                <checkbox
                                    :ref="'checkbox_' + rowIndex"
                                    :propChecked="allChecked || row._checked || groupCheckboxTypeHandle(rowIndex, row)"
                                    :propHalfChecked="groupCheckboxHalfCheck(rowIndex, row)"
                                    :propValue="rowIndex"
                                    @change="groupRowCheckedChange"
                                    :key="row.id + rowIndex"
                                ></checkbox>
                            </div>
                        </td>
                        <td
                            v-if="row._groupRow"
                            class="body-cell body-cell-group"
                            :colspan="
                                columns[0].checkbox && options.group.groupCheckbox ? columns.length - 1 : columns.length
                            "
                        >
                            <div class="switch" @click.stop="switchRowGroup(row)">
                                <roundAddImg
                                    v-if="!row._expand"
                                    v-bind="{ color: '#007aff', width: '16px', height: '16px' }"
                                />
                                <roundMinusImg
                                    v-if="row._expand"
                                    v-bind="{ color: '#007aff', width: '16px', height: '16px' }"
                                />
                            </div>
                            <div v-html="getRowGroupFormatter(row)"></div>
                        </td>
                        <template v-else v-for="(field, cellIndex) in columns">
                            <td
                                v-if="getFieldVisible(field)"
                                :style="cellStyleFormatter(field, row)"
                                :key="cellIndex"
                                :class="{ columnsBoder: cellIndex === columnsSelectIndex }"
                                @dblclick="td_dbClick(row, rowIndex, field)"
                                @click="cellClick(row, rowIndex, field)"
                            >
                                <template v-if="field.editor && renderEditor(field, row, rowIndex)">
                                    <div class="body-cell">
                                        <cellEditor
                                            :options="renderEditor(field, row, rowIndex)"
                                            :row="row"
                                            :rowIndex="rowIndex"
                                            :cellIndex="cellIndex"
                                            :ref="rowIndex + '_' + field.field"
                                        />
                                    </div>
                                </template>
                                <template v-else>
                                    <div
                                        v-if="field.checkbox"
                                        @mouseup.stop="cellCheckboxClick(rowIndex, $event)"
                                        @click.stop="checkboxClickFn(row, rowIndex, $event)"
                                        class="body-cell select-row-checkbox-cell"
                                    >
                                        <checkbox
                                            :ref="'checkbox_' + rowIndex"
                                            :propChecked="allChecked || row._checked || checkboxTypeHandle(rowIndex)"
                                            :propValue="rowIndex"
                                            @change="rowCheckedChange"
                                            @click.native="checkboxClickFn(row, rowIndex, $event)"
                                            :key="row.id + rowIndex"
                                        ></checkbox>
                                    </div>
                                    <div
                                        v-else-if="field.radio"
                                        @mouseup.stop="cellCheckboxClick(rowIndex, $event)"
                                        @click.stop
                                        class="body-cell select-row-radio-cell"
                                    >
                                        <radio
                                            :ref="'checkbox_' + rowIndex"
                                            :propChecked="allChecked || row._checked"
                                            :propValue="rowIndex"
                                            :propId="row.id"
                                            @change="rowRadioChange"
                                            @click.native="checkboxClickFn(row, rowIndex, $event)"
                                            v-if="options.type !== 'treeGrid'"
                                        ></radio>
                                    </div>
                                    <div v-else-if="field.index === true" class="body-cell row-index-cell">
                                        {{ getRowIndex(row.gridIndex || rowIndex + 1) }}
                                    </div>
                                    <div
                                        v-else-if="field.type === 'link'"
                                        class="body-cell"
                                        :style="{ textAlign: contentAlign(field) }"
                                    >
                                        <div
                                            class="cell-icon"
                                            v-if="field.iconFormatter"
                                            :class="field.iconFormatter(row)"
                                            :style="{ cursor: 'pointer' }"
                                        ></div>
                                        <a
                                            href="javascript:"
                                            class="cell-tag"
                                            :class="field.tagFormatter && field.tagFormatter().type"
                                            @mouseenter="showPoptipPanel($event, rowIndex, field, row)"
                                            @mouseleave.stop="removeErrorPanel"
                                            @mousedown.stop="removeErrorPanel"
                                            v-html="getFieldValue(row, field.field)"
                                        ></a>
                                    </div>
                                    <!--下拉框类型显示值-->
                                    <div v-else-if="field.type === 'select'" class="body-cell">
                                        <div
                                            class="readonly-text"
                                            @mouseenter="showPoptipPanel($event, rowIndex, field, row)"
                                            @mouseleave.stop="removeErrorPanel"
                                            v-html="getSelectText(field, row, rowIndex)"
                                        ></div>
                                    </div>
                                    <!--checkbox类型显示值-->
                                    <div v-else-if="field.type === 'checkbox'" class="body-cell">
                                        <div class="readonly-text" v-html="getCheckboxText(field, row, rowIndex)"></div>
                                    </div>
                                    <div v-else-if="field.type === 'richText'" class="body-cell">
                                        <richTextField
                                            class="readonly-text"
                                            :propReadonly="true"
                                            :options="{ field: field }"
                                            :propValue="row[field.field]"
                                            :rowIndex="rowIndex"
                                        ></richTextField>
                                    </div>
                                    <!-- 电子签名 -->
                                    <div v-else-if="field.type === 'sign'" class="body-cell">
                                        <signField
                                            :propValue="getFieldValue(row, field.field)"
                                            :options="{ field: field }"
                                            :propReadonly="true"
                                        ></signField>
                                    </div>
                                    <!--simpleCheckbox-->
                                    <div v-else-if="field.type === 'simpleCheckbox'" class="body-cell">
                                        <simple-checkbox-field
                                            :propValue="getFieldValue(row, field.field)"
                                            :options="{ field: field }"
                                            :propReadonly="true"
                                            key="readonly-simpleCheckbox"
                                        />
                                    </div>
                                    <!--treeGrid字段类型-->
                                    <div v-else-if="field.type === 'tree'" class="body-cell">
                                        <treeGridNode
                                            :ref="'node_' + rowIndex"
                                            v-bind="{
                                                row,
                                                single: options.single,
                                                fieldOptions: field,
                                                rowIndex,
                                                checkedNodes,
                                                checked: getChecked(row, options.single, options.cascadeCheck),
                                                halfCheck: getHalfCheck(row, options.cascadeCheck)
                                            }"
                                            @change="nodeClickHandle"
                                            v-if="options.type === 'treeGrid'"
                                        ></treeGridNode>
                                    </div>
                                    <!-- 拖动 -->
                                    <div v-else-if="field.type === 'drag'" class="body-cell">
                                        <div
                                            class="drag"
                                            :class="{ cur: dragDownIndex !== null }"
                                            @mousemove="dragMove($event)"
                                            @mouseup="dragUp(row, rowIndex)"
                                            @mouseenter="dragEnter(row, rowIndex)"
                                        >
                                            <dragImg
                                                v-bind="{
                                                    width: '14px',
                                                    height: '14px',
                                                    color: dragDownIndex === rowIndex ? '#007AFF' : '#999'
                                                }"
                                                @mousedown.native="dragDown(row, rowIndex, $event)"
                                            />
                                        </div>
                                    </div>
                                    <div v-else class="body-cell" :field="field.field">
                                        <div
                                            class="cell-icon"
                                            v-if="field.iconFormatter"
                                            :class="field.iconFormatter(row)"
                                        ></div>
                                        <cellTagField
                                            v-else-if="field.tagFormatter"
                                            :options="field.tagFormatter(row)"
                                        ></cellTagField>
                                        <div
                                            v-else
                                            class="readonly-text"
                                            :style="{
                                                lineHeight: checkReadonlyText(rowIndex, field, row)
                                            }"
                                            @mouseenter="showPoptipPanel($event, rowIndex, field, row)"
                                            @mouseleave.stop="removeErrorPanel"
                                        >
                                            <span
                                                class="text"
                                                :style="getReadonlyTextStyle(field)"
                                                v-html="getReadonlyText(rowIndex, field, row)"
                                            ></span>
                                            <span class="addon" v-if="field.addon">{{ field.addon }}</span>
                                        </div>
                                    </div>
                                </template>
                            </td>
                        </template>
                    </tr>
                    <totalRow
                        ref="totalFixed"
                        v-show="Gikam.isNotEmpty(this.options.sum.fields) && options.sum.fixed"
                        :options="options"
                        :state="$store.state"
                    ></totalRow>
                </tbody>
            </table>
        </scroll>
    </div>
</template>

<script>
import Gikam from '../../../core/gikam-core';
import treeGridNode from './treeGridNode.vue';
import jQuery from 'jquery';
import Vue from 'vue';
import cellEditor from './cellEditor';
import { mapState } from 'vuex';
import totalRow from './totalRow.vue';

export default {
    props: {
        options: Object,
        fixedIndex: Array,
        rowActiveId: [String, Number],
        propCheckeds: Array
    },

    computed: {
        ...mapState(['columnsSelectIndex']),

        columns() {
            return this.$store.state.columns;
        },

        fixedData() {
            const fixedIndex = this.fixedIndex.map(item => {
                return item !== -1 && this.options.data.length + item;
            });
            return this.options.data.filter((item, index) => fixedIndex.indexOf(index) > -1);
        },

        width() {
            return this.$store.state.width + 'px';
        },

        scrollTop() {
            return this.$store.state.scrollTop;
        },

        scrollLeft() {
            return this.$store.state.scrollLeft;
        },

        containerWidth() {
            if (this.options.scroll === 'visible') {
                return 'calc(100% - 17px)';
            }
            return '100%';
        }
    },

    inject: {
        grid: {
            default: null
        }
    },

    provide() {
        return {
            checkedNodes: this.checkedNodes
        };
    },

    beforeUpdate() {
        if (this.checkeds.length == 0) {
            this.totalChecked.num = this.checkeds.length;
            this.groupNum = 0;
            this.isCornerMarkerShow(this.totalChecked.num);
        } else {
            this.totalChecked.num = this.checkeds.length - this.groupNum;
            this.isCornerMarkerShow(this.totalChecked.num);
        }
    },

    methods: {
        getCellWidth(item) {
            return item.width ? 'width:' + (item.width + 'px') : null;
        },

        // 获取表头隐藏属性hideHeader
        getFieldHideHeader(field) {
            if (field.hideHeader) {
                return false;
            }
            return true;
        },

        //td双击事件
        td_dbClick(row, rowIndex, field) {
            const fieldName = field.field;
            this.grid.trigger('cellDbClick', fieldName, row, rowIndex);
        },

        // checkbox通过index判断是否选中
        checkboxTypeHandle(index) {
            if (this.checkeds.includes(this.handleIndex(index))) {
                return true;
            }
            return false;
        },

        //计算实际索引
        handleIndex(index) {
            if (Gikam.isNotEmpty(this.fixedIndex)) {
                return this.options.data.length + this.fixedIndex[0] + index;
            }
            return index;
        },

        // 具有分组数据时，判断checkbox是否选中
        groupCheckboxTypeHandle(index, row) {
            const i = this.handleIndex(index);
            let checked = false;
            this.options.data.forEach(item => {
                if (item._parentGroupIndex == row._groupIndex && this.checkeds.includes(i)) {
                    checked = true;
                }
            });
            if (!this.checkeds.includes(i) && checked) {
                this.groupNum++;
                this.checkeds.push(i);
            } else if (this.checkeds.includes(i) && !checked) {
                this.groupNum--;
                this.checkeds.splice(this.checkeds.indexOf(i), 1);
            }
            return checked;
        },

        groupCheckboxHalfCheck(index, row) {
            const i = this.handleIndex(index);
            let groupChildChecked = 0;
            let allGroupChildRow = 0;
            this.options.data.forEach(item => {
                if (item._parentGroupIndex == row._groupIndex) {
                    allGroupChildRow++;
                    if (this.checkeds.includes(i)) {
                        groupChildChecked++;
                    }
                }
            });
            if (groupChildChecked == 0 || groupChildChecked == allGroupChildRow) {
                return false;
            } else {
                return true;
            }
        },

        // 全选状态自动改变
        allCheckboxType() {
            if (this.checkeds.length !== 0 && this.checkeds.length === this.options.data.length) {
                this.allChecked = true;
            } else {
                this.allChecked = false;
            }
        },

        getRowGroupFormatter(row) {
            return this.options.group.formatter.call(this.grid, row);
        },

        getRowHidden(row) {
            let parentGroupRow;
            this.options.data.forEach(item => {
                if (item._groupIndex == row._parentGroupIndex) {
                    parentGroupRow = item;
                }
            });
            row._hidden = !parentGroupRow._expand;
            return row._hidden;
        },

        getCheckNum(row) {
            let checkNum = 0;
            row.children &&
                row.children.forEach(item => {
                    if (item.checked) {
                        checkNum++;
                    }
                });
            return checkNum;
        },

        getHalfCheckNum(row) {
            let halfCheckNum = 0;
            row.children &&
                row.children.forEach(item => {
                    if (this.getHalfCheck(item)) {
                        halfCheckNum++;
                    }
                });
            if (Gikam.isEmpty(row.children)) halfCheckNum = 1;
            return halfCheckNum;
        },

        getChecked(row, single, cascadeCheck) {
            if (single) {
                return row.checked;
            }
            if (!cascadeCheck) {
                return row.checked;
            }
            let checkNum = this.getCheckNum(row);
            if (row.children && checkNum == 0) {
                row.checked = false;
            } else if (row.children && checkNum != 0) {
                row.checked = true;
            }
            return row.checked;
        },

        getHalfCheck(row, cascadeCheck) {
            if (!cascadeCheck) {
                return false;
            }
            let num;
            if (row.children) {
                num = row.children.length;
            } else {
                num = 0;
            }
            let checkNum = this.getCheckNum(row);

            if (num == 0 || (checkNum == num && this.getHalfCheckNum(row) == 0)) {
                return false;
            } else {
                return true;
            }
        },

        switchRowGroup(row) {
            row._expand = !row._expand;
            this.options.data
                .filter(subRow => {
                    return subRow._parentGroupIndex === row._groupIndex;
                })
                .forEach(item => {
                    item._hidden = !row._expand;
                });
        },

        checkReadonlyText(rowIndex, field, row) {
            let val = this.getReadonlyText(rowIndex, field, row);
            if (val && typeof val == 'string' && val.indexOf('<sup>') != -1) {
                return '20px';
            } else {
                return '24px';
            }
        },

        contentAlign(field) {
            return field.contentAlign ? field.contentAlign : this.options.contentAlign;
        },

        getReadonlyText(rowIndex, field, row) {
            let text = Gikam.getFieldValue(row, field.field);
            if (field.formatter) {
                return field.formatter(rowIndex, Gikam.getFieldValue(row, field.field), row);
            } else if (field.type === 'select' && Gikam.isNotEmpty(field.items) && text) {
                return field.items.find(item => item.value === text).text;
            } else {
                return text;
            }
        },

        // 气泡
        showPoptipPanel(event, rowIndex, field, row) {
            if (field.titleTip === false) {
                return;
            }
            if (window.screen.width < 768) return;
            let formatterValue = this.getReadonlyText(rowIndex, field, row);
            if (Gikam.isEmpty(formatterValue)) {
                return false;
            }
            if (field.titleFormatter) {
                formatterValue = field.titleFormatter(row);
            }
            if (!Gikam.poptipPanel) {
                const top = event.clientY + 20;
                const left = event.clientX;
                let lineHeight;
                if (
                    formatterValue &&
                    typeof formatterValue == 'string' &&
                    formatterValue.indexOf('<sup>') != -1 &&
                    formatterValue.indexOf('</p>') == -1
                ) {
                    lineHeight = 10;
                } else {
                    lineHeight = 19;
                }
                Gikam.poptipPanel = new Vue({
                    el: Gikam.createDom('div', document.body),
                    data: {
                        formatterValue: formatterValue,
                        tipStyle: {
                            top: top + 'px',
                            backgroundColor: '#fff',
                            maxWidth: '400px',
                            height: 'auto'
                        }
                    },
                    methods: {
                        options() {
                            // 解决气泡居边产生滚动条
                            let poptip = Gikam.jQuery(this.$el);
                            let win = Gikam.jQuery(window);
                            let width = poptip.width();
                            let height = poptip.height();
                            let window_width = win.width();
                            let window_height = win.height();
                            if (left + width + 24 > window_width) {
                                this.tipStyle = { top: top, right: '16px' };
                            } else {
                                this.tipStyle = { top: top, left: left + 'px' };
                            }
                            if (top + height + 24 > window_height) {
                                this.tipStyle.top = top - 40 + 'px';
                            }
                            this.tipStyle['max-width'] = '400px';
                            this.tipStyle['height'] = 'auto';
                            this.tipStyle['lineHeight'] = lineHeight + 'px';
                        }
                    },
                    mounted() {
                        this.options();
                    },
                    template: `<div class='poptip-message-panel' :style='tipStyle' v-html=formatterValue></div>`
                });
            }
        },

        // 关闭气泡
        removeErrorPanel() {
            if (Gikam.poptipPanel) {
                Gikam.removeDom(Gikam.poptipPanel.$el);
                Gikam.poptipPanel.$destroy();
                Gikam.poptipPanel = null;
            }
        },

        getFieldValue(data, field) {
            return Gikam.getFieldValue(data, field);
        },

        renderEditor(field, row, rowIndex) {
            if (field.onBeforeEditorRender) {
                const editorRenderFlag = field.onBeforeEditorRender.call(this.grid, row, rowIndex);
                if (editorRenderFlag === false) {
                    return false;
                }
                if (editorRenderFlag === true) {
                    return field;
                }
                return Gikam.deepExtend(field, editorRenderFlag);
            }
            return field;
        },

        rowCheckedChange(checked, rowIndex, event, id, triggerBySelf = true) {
            event && event.stopPropagation();
            const row = this.options.data[rowIndex];
            if (checked) {
                this.checkeds.push(rowIndex);
                this.checkeds.sort((a, b) => {
                    return a - b;
                });
                this.grid.trigger('select', row, triggerBySelf);
                this.totalChecked.num = this.totalChecked.num + 1;
                this.isCornerMarkerShow(this.totalChecked.num);
            } else {
                this.checkeds.splice(this.checkeds.indexOf(rowIndex), 1);
                this.grid.trigger('unSelect', row, triggerBySelf);
                this.totalChecked.num = this.totalChecked.num - 1;
                this.isCornerMarkerShow(this.totalChecked.num);
            }
            this.allCheckboxType();
        },

        groupRowCheckedChange(checked, rowIndex, event) {
            const index = this.handleIndex(rowIndex);
            event && event.stopPropagation();
            const row = this.options.data[rowIndex];
            let groupRow = [];
            let groupChildrenRow = [];
            this.options.data.forEach(item => {
                if (item._parentGroupIndex == row._groupIndex) {
                    groupRow.push(index);
                    groupChildrenRow.push(item);
                }
            });
            const { onGroupSelect, onGroupUnSelect } = this.grid.options;
            if (checked) {
                this.groupNum++;
                this.checkeds.push(index);
                this.checkeds.sort((a, b) => {
                    return a - b;
                });
                groupRow.forEach(item => {
                    if (this.checkeds.indexOf(item) == -1) {
                        this.$refs['checkbox_' + item][0].triggerChange();
                    } else {
                        return;
                    }
                });
                onGroupSelect?.call(this.grid, groupChildrenRow);
            } else {
                this.groupNum--;
                this.checkeds.splice(this.checkeds.indexOf(index), 1);
                if (!this.rowActived) {
                    groupRow.forEach(item => {
                        if (this.checkeds.indexOf(item) !== -1) {
                            this.$refs['checkbox_' + item][0].triggerChange();
                        } else {
                            return;
                        }
                    });
                    onGroupUnSelect?.call(this.grid, groupChildrenRow);
                } else {
                    return;
                }
            }
        },

        isCornerMarkerShow(num) {
            if (num > 1) {
                this.totalChecked.isShow = true;
            } else {
                this.totalChecked.isShow = false;
            }
        },

        rowRadioChange(checked, rowIndex, event, id) {
            let checkedRowIndex = this.checkeds[0];
            if (Gikam.isNotEmpty(checkedRowIndex)) {
                this.$refs['checkbox_' + checkedRowIndex][0].checked = false;
                this.checkeds.length = 0;
            }
            this.rowCheckedChange(checked, rowIndex, event);
            this.totalChecked.num = 0;
            this.isCornerMarkerShow(this.totalChecked.num);

            if (this.options.activeOnCheck) {
                const row = this.$refs['row-' + id];
                row && row[0].click((this._clickRadio = true));
                this._clickRadio = false;
            }
        },

        nodeClickHandle(checked, single, row) {
            this.$set(row, 'checked', checked);
            this.grid.trigger('nodeSelected', row, checked);
            if (single) {
                if (checked) {
                    this.options.data
                        .filter(item => item.checked)
                        .forEach(item => {
                            this.$set(item, 'checked', false);
                            this.grid.trigger('nodeSelected', item, false);
                        });
                    this.$set(row, 'checked', true);
                }
            } else {
                if (!this.options.cascadeCheck) {
                    return;
                }
                const childrenRow = this.getAllChildren(row, []);
                if (Gikam.isNotEmpty(childrenRow)) {
                    childrenRow.forEach(_row => {
                        this.$set(_row, 'checked', checked);
                        this.grid.trigger('nodeSelected', _row, checked);
                    });
                }
            }
        },

        // 按下shift点击复选框，禁止鼠标选中文字。点选的时候容易选中文字.
        selectstart() {
            window.onkeydown = function(e) {
                if (e.shiftKey) {
                    window.onselectstart = function(event) {
                        event = window.event || event;
                        event.returnValue = false;
                    };
                }
            };
            window.onkeyup = function() {
                window.onselectstart = function(event) {
                    event = window.event || event;
                    event.returnValue = true;
                };
            };
        },

        // 先去一个index区间进行复选[选中]
        intervalCheckClick(shiftIndex) {
            for (
                let i = Math.min(this.lastActiveNodeIndex, shiftIndex);
                i < Math.max(this.lastActiveNodeIndex, shiftIndex);
                i++
            ) {
                let checkNode = this.grid.model.$refs.vm.$refs['checkbox_' + i][0];
                let checkType = checkNode.checked;
                !checkType && checkNode.$el.click();
            }
        },

        // 判断是否按下shift，并取相应的index
        getShiftCheckboxIndex(index, e) {
            if (this.lastActiveNodeIndex === undefined || this.lastActiveNodeIndex === index) {
                this.lastActiveNodeIndex = index;
            } else {
                if (e && e.shiftKey) {
                    this.$nextTick(() => {
                        this.intervalCheckClick(index);
                    });
                } else {
                    // 这里是不按shift时，最后一次点击index值
                    this.lastActiveNodeIndex = index;
                }
            }
        },

        checkboxClickFn(data, index, e) {
            // 加100ms，为了使其慢于click方法。比如：取getData()时
            setTimeout(() => {
                this.getShiftCheckboxIndex(index, e);
                this.grid.trigger('checkboxClick', data, index);
            }, 100);
        },

        getSelectText(field, item, rowIndex) {
            let selectText = '';
            let value = Gikam.getFieldValue(item, field.field);
            if (field.multiple && value && value.indexOf(',') > -1) {
                value = value.split(',');
                field.items.forEach(select => {
                    if (select.value && value.some(v => v === select.value)) {
                        const text = field.formatter
                            ? field.formatter(rowIndex, select.value, item, select.text)
                            : select.text;
                        selectText += text + ',';
                    }
                });
                return selectText.substr(0, selectText.length - 1);
            }
            Gikam.each(field.items, function() {
                if (this.value == value) {
                    selectText = this.text;
                    return false;
                }
            });
            return field.formatter ? field.formatter(rowIndex, value, item, selectText) : selectText;
        },

        getCheckboxText(field, item) {
            const key = field.field;
            if (item[key]) {
                const value = JSON.parse(item[key]);
                const text = value.reduce((total, curr) => {
                    if (parseInt(curr.checked) === 1) {
                        total += curr.text + ',';
                    }
                    return total;
                }, '');
                return text.substr(0, text.length - 1);
            } else {
                return '';
            }
        },

        cellClick(row, rowIndex, field) {
            if (field.type === 'link') {
                const window = Gikam.getInstance(jQuery(this.$el).closest('.window'));
                if (window) {
                    window.model.storage = {
                        grid: this.grid,
                        index: rowIndex
                    };
                }
            }
            this.grid.trigger('cellClick', field.field, Gikam.deepExtend(row), rowIndex);
        },

        cellCheckboxClick(index, e) {
            this.getShiftCheckboxIndex(index, e);
            const checkbox = this.$refs['checkbox_' + index];
            checkbox && checkbox[0].clickHandle();
        },

        rowActive(index) {
            this.grid.activeRowByIndex(this.handleIndex(index));
        },

        cellStyleFormatter(fieldOptions, row) {
            if (fieldOptions.styleFormatter) {
                return fieldOptions.styleFormatter(row);
            }
        },

        getRowStyle(row) {
            if (this.options.rowStyleFormatter) {
                return this.options.rowStyleFormatter(row);
            }
        },

        getAllChildren(row, result) {
            const _this = this;
            if (row.children) {
                row.children.forEach(item => {
                    if (item.children) {
                        _this.getAllChildren(item, result);
                    }
                    result.push(item);
                });
            }
            return result;
        },

        /* 拖动的方法 */
        dragMove(e) {
            if (this.dragDownIndex === null) return;
            if (e.pageY - this.dragPageY > 0) {
                this.dragEnterType = 'bottom';
            }
            if (e.pageY - this.dragPageY < 0) {
                this.dragEnterType = 'top';
            }
            this.dragPageY = e.pageY;
        },

        dragDown(data, index, e) {
            this.scrollAutomaticSlide = true;
            this.dragDownIndex = index;
            this.dragPageY = e.pageY;
            this.dragDownData = data;
        },

        dragUp(data, index) {
            this.scrollAutomaticSlide = false;
            if (data && this.dragDownIndex !== null && this.dragDownIndex !== index) {
                // 排序逻辑
                this.options.data.splice(this.dragDownIndex, 1);
                for (let i = 0; i < this.options.data.length; i++) {
                    if (this.options.data[i].field === data.field) {
                        let index = { top: i, bottom: i + 1 }[this.dragEnterType];
                        this.options.data.splice(index, 0, this.dragDownData);
                        break;
                    }
                }
            }

            this.dragDownIndex = null;
            this.dragEnterIndex = null;
            this.dragPageY = 0;
            this.dragEnterType = null;
            this.dragDownData = null;
        },

        dragEnter(data, index) {
            this.dragEnterIndex = index;
        },

        dragLeave() {
            if (this.dragDownIndex === null) {
                return;
            }
            this.dragUp();
        },

        getFieldVisible(options) {
            const visible = options.visible;
            return visible === undefined || visible === '1' || visible === true;
        },

        getReadonlyTextStyle(field) {
            return {
                textAlign: this.contentAlign(field),
                paddingRight: field.addon ? '5px' : ''
            };
        },

        getRowIndex(index) {
            if (this.options.indexKeep) {
                return this.$store.state.pageSize * (this.$store.state.pageNum - 1) + index;
            }
            return index;
        }
    },

    mounted() {
        this.$refs.totalFixed.$nextTick(() => {
            if (Gikam.isNotEmpty(this.fixedIndex)) {
                this.$refs['row-fixed-body'].children.forEach(tr => {
                    this.rowFixedHeight.push(tr.offsetHeight);
                });
                this.height = this.rowFixedHeight.reduce((cur, item) => (cur += item), 0) + 'px';
            }
        });
    },

    watch: {
        scrollLeft(val) {
            this.$refs.rowFixed.scrollTo({ x: val });
        },

        propCheckeds: {
            handler(val) {
                this.checkeds = val;
                this.allCheckboxType();
            },
            deep: true
        },

        checkeds: {
            handler(val) {
                this.$emit('checkedsHandle', val);
            },
            deep: true
        }
    },

    data() {
        return {
            Gikam: Gikam,
            allChecked: false,
            checkeds: [],
            checkedNodes: [],
            activeRow: null,
            activeRowData: null,
            editorInvisible: this.options.editorInvisible,
            totalChecked: {
                isShow: false,
                num: 0
            },
            groupNum: 0, // 全选时类型为group的数量
            rowActived: false,
            transition: true, // 控制固定列滚动是否有动画
            scrollAutomaticSlide: false,
            height: null, //实际显示的高度
            rowFixedHeight: [] //每一行的高度集合
        };
    },

    components: {
        totalRow,
        treeGridNode,
        cellEditor,
        cellTagField: {
            props: {
                options: {
                    type: Object,
                    default: () => {
                        return {
                            type: '',
                            value: ''
                        };
                    }
                }
            },
            template:
                '<div class="cell-tag" :class="options.type"><div class="cell-tag-text">{{options.value}}</div></div>'
        }
    }
};
</script>

<style>
.row-fixed-bottom {
    width: 100%;
    position: absolute;
    z-index: 1;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0px -2px 4px 0px #ccc;
}

.grid > .grid-body table > thead th {
    height: 0;
    padding: 0;
    border: none;
    border-right: 1px solid #eee;
}

.grid > .grid-body table > tbody > tr {
    transition: transform 0.2s ease-out;
}

.grid > .grid-body table > tbody > .hide {
    float: left;
    visibility: hidden;
    transform: translateY(-100%);
    height: 0;
}

.grid > .grid-body table > tbody > tr > td {
    height: 32px;
    padding: 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    text-align: left;
    border-right: 1px solid #eee;
}

.grid.columns-fill > .grid-body table > tbody > tr > td:not(.row-span):last-child {
    border-right: none;
}

.grid > .grid-body .body-cell.select-row-checkbox-cell,
.grid > .grid-body .body-cell.select-row-radio-cell,
.grid > .grid-body .body-cell.row-index-cell {
    display: flex;
    justify-content: center;
}

.grid > .grid-body table > tbody > tr:nth-child(odd) {
    background-color: rgba(11, 184, 148, 0.05);
}

.grid > .grid-body table > tbody > tr > .no-record-cell {
    background-color: #fff;
}

.grid > .grid-body table > tbody > tr > td > .body-cell a {
    text-decoration: none;
    color: #007aff;
    height: auto;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.grid > .grid-body table > tbody > tr > td > .body-cell {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 8px;
    white-space: nowrap;
}

.grid > .grid-body table > tbody > tr > td > .body-cell p {
    margin: 0;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-icon {
    width: 25px;
    height: 25px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag > .cell-tag-text {
    height: 24px;
    line-height: 23px !important;
    padding: 0 8px;
    border-radius: 4px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag.success,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag.success > .cell-tag-text {
    color: #00d94d;
    background-color: #e5fbed;
    border: 1px solid #66e894;
    padding: 0 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag.danger,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag.danger > .cell-tag-text {
    color: #ff3b30;
    background-color: #ffe2e0;
    border: 1px solid #ff8983;
    padding: 0 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag.info,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag.info > .cell-tag-text {
    color: #007aff;
    background-color: #e5f1ff;
    border: 1px solid #66afff;
    padding: 0 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag.warning,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag.warning > .cell-tag-text {
    color: #ffba00;
    background-color: #fff8e5;
    border: 1px solid #ffd666;
    padding: 0 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag.guard,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag.guard > .cell-tag-text {
    color: #ff8936;
    background-color: #fff3eb;
    border: 1px solid #ffd886;
    padding: 0 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag.fine,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag.fine > .cell-tag-text {
    color: #00c4b4;
    background-color: #e5f9f7;
    border: 1px solid #66dcd2;
    padding: 0 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell:not(.select-row-checkbox-cell):not(.select-row-radio-cell) > * {
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 24px;
    line-height: 24px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell .simple-checkbox {
    display: flex;
    justify-content: center;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > .readonly-text {
    width: 100%;
    display: block;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > .readonly-text .text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > .readonly-text .addon {
    width: 48px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* .grid > .grid-body table > tbody > tr > td > .body-cell > .readonly-text sub {
    font-size: 7px;
    position: relative;
    top: -2px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > .readonly-text sup {
    font-size: 7px;
    position: relative;
    top: 3px;
} */

.grid > .grid-body table > tbody > tr > td > .body-cell > .text-input > .readonly-text {
    /* 解决grid组件编辑录入的结果和不可编辑的内容不对齐的问题 */
    padding-left: 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell .select .select-container .placeholder {
    font-size: 12px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell.no-record {
    height: 78px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.grid > .grid-body table > tbody > tr > td > .body-cell.no-record > .no-record-div {
    box-sizing: content-box;
    padding: 0 0 5px 0;
    height: 70px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > .no-record-div > .no-record-img {
    display: block;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > .no-record-div > .no-record-text {
    display: block;
    margin-top: -10px;
}
.grid > .grid-body table > tbody > tr:last-child {
    border-bottom: 1px solid #eee;
}

.grid > .toolbar > .item.dropDownMenuMore {
    margin-left: 8px;
}

.grid .item.dropDownMenuMore {
    order: 1;
}

.grid > .custom-content {
    color: rgba(0, 0, 0, 0.65);
    width: 100%;
    font-size: 14px;
}

.grid > .grid-body table > tbody > tr:hover {
    background-color: rgba(11, 184, 148, 0.08);
}

.grid > .grid-body table > tbody > tr.active {
    background-color: rgba(0, 122, 255, 0.16);
}

.grid > .toolbar > .search > .generic-query-btn {
    display: flex;
    align-items: center;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding: 0 0 0 9px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    width: 150px;
    justify-content: space-between;
    cursor: pointer;
    margin-right: 10px;
}

.grid > .toolbar > .search > .filter-dropdown-btn > .text {
    white-space: nowrap;
}

.grid > .toolbar > .search > .generic-query-btn.active {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.grid > .toolbar > .search > .generic-query-btn > .drop {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
}

.grid > .toolbar > .search > .generic-query-btn > .drop > .search-icon {
    width: 12px;
    height: 12px;
    background: url(../../../../img/grid-generic-query.png) no-repeat center;
}

.grid > .toolbar > .search > .generic-query-btn > .refresh-icon {
    width: 34px;
    height: 100%;
    background: url(../../../../img/grid-generic-refresh.png) no-repeat center;
    border-left: 1px solid #d9d9d9;
}

.grid > .toolbar > .search > .generic-query-btn > .refresh-icon.active {
    background: url(../../../../img/grid-generic-refresh-blue.png) no-repeat center;
}

.grid > .toolbar > .search > .generic-query-btn > .drop > .down-arrow {
    width: 28px;
    height: 12px;
    background: url(../../../../img/grid-generic-query-down-arrow.png) no-repeat center;
}

.grid .row-group > .body-cell-group {
    line-height: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.grid .row-group > .body-cell-group > .switch {
    float: left;
    height: 100%;
    width: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid [parent-group-index].hidden {
    display: none;
}

.grid.grid-fixed {
    position: absolute;
    left: 0;
    bottom: 44px;
    padding: 0 !important;
    height: auto;
    overflow: hidden;
}

.grid-fixed .grid-body {
    overflow: hidden;
}

.__rail-is-horizontal {
    z-index: 2 !important;
}

.grid.line-feed
    > .grid-body
    table
    > tbody
    > tr
    > td
    > .body-cell:not(.select-row-checkbox-cell):not(.select-row-radio-cell)
    > * {
    white-space: normal;
    height: auto;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell > .readonly-text {
    height: auto;
    white-space: normal;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell {
    white-space: normal;
    line-height: 20px;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell > .readonly-text {
    padding: 5px;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell > a {
    padding: 5px;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell .invisible .readonly-text {
    padding: 5px;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell .invisible .readonly-text .content-wrapper {
    white-space: normal;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell .validate-error .readonly-text {
    margin-right: 24px;
}

.grid > .grid-body .body-cell > .drag {
    width: 100%;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 拖拽的样式 */
.grid > .grid-body .body-cell > .drag svg {
    cursor: pointer;
}

.grid > .grid-body .body-cell > .drag.cur {
    cursor: pointer;
}

.grid > .grid-body table > tbody > tr td {
    position: relative;
}

.grid > .grid-body table > tbody > tr.drag-active td::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #007aff;
}

.grid > .grid-body table > tbody > tr.drag-active td::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: #007aff;
}

.grid > .grid-body table > tbody > tr.drag-active td:first-child {
    border-left: 1px solid #007aff;
}

.grid > .grid-body table > tbody > tr.drag-active td:last-child {
    border-right: 1px solid #007aff;
}

.user-select {
    user-select: none;
}

.grid > .grid-body table > tbody > tr.drag-border-top td::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 2px;
    background-color: rgba(0, 122, 255, 0.6);
}

.grid > .grid-body table > tbody > tr.drag-border-bottom td::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 2px;
    background-color: rgba(0, 122, 255, 0.6);
}

.grid > .grid-body > .scroll-type-top,
.grid > .grid-body > .scroll-type-bottom {
    width: 100%;
    height: 8px;
    z-index: 3;
}

.grid > .grid-body > .scroll-type-top {
    position: absolute;
    top: 0;
    left: 0;
}

.grid > .grid-body > .scroll-type-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
}

.grid > .grid-body > .scroll-type-top.top {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(225, 225, 225, 0.1));
}

.grid > .grid-body > .scroll-type-bottom.bottom {
    background-image: linear-gradient(rgba(225, 225, 225, 0.1), rgba(0, 0, 0, 0.1));
}

.poptip-message-panel a {
    flex: auto !important;
}

/* 列边框 */
.grid > .grid-body table > tbody > tr:nth-last-child(1) > td.columnsBoder {
    border-bottom: 2px solid #007aff;
}
.grid > .grid-body table > tbody > tr > td.columnsBoder {
    position: relative;
}
.grid > .grid-body table > tbody > tr > td.columnsBoder::before {
    content: '';
    height: calc(100% + 2px);
    position: absolute;
    border-left: 2px solid #007aff;
    left: -1px;
    top: 0;
    overflow: hidden;
}
.grid > .grid-body table > tbody > tr > td.columnsBoder::after {
    content: '';
    height: calc(100% + 2px);
    position: absolute;
    border-left: 2px solid #007aff;
    right: -1px;
    top: 0;
    overflow: hidden;
}

.row-fixed-top {
    position: absolute;
    top: 0;
}
</style>
