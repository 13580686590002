<!--suppress CssUnusedSymbol -->
<template>
    <div class="modal" :class="classObject">
        <div
            class="modal-container"
            :style="{
                width: width + 'px',
                height: height + 'px',
                top: top + 'px',
                left: left + 'px',
                position: top ? 'absolute' : ''
            }"
            :class="{ 'no-user-select': resizing }"
            ref="modalContainer"
        >
            <div class="modal-header" @mousedown="dragDown" v-if="!options.closeHeader">
                <div class="modal-title">{{ propI18N(options.title) }}</div>
                <div class="header-center" v-html="headerCenter"></div>
                <div class="modal-toolbar">
                    <button class="max" v-on:click="max()" :title="$i18n('modal.max')" v-if="showMaxBtn">
                        <maximizeImg width="12px" height="12px" />
                    </button>

                    <button class="restore" v-on:click="restore()" :title="$i18n('modal.restore')">
                        <recoveryImg width="12px" height="12px" />
                    </button>

                    <button v-on:click="close()" :title="$i18n('modal.close')" v-if="showCloseBtn">
                        <shutdownImg width="12px" height="12px" />
                    </button>
                </div>
            </div>
            <div class="modal-body" :style="options.bodyStyle">
                <slot name="modal-body"></slot>
                <iframe v-if="options.src" :src="options.src" ref="iframe"></iframe>
                <div v-if="options.src" v-show="resizing" class="moving"></div>
            </div>
            <div class="modal-tool modal-top" @mousedown="dragDown($event, 'top')"></div>
            <div class="modal-tool modal-bottom" @mousedown="dragDown($event, 'bottom')"></div>
            <div class="modal-tool modal-left" @mousedown="dragDown($event, 'left')"></div>
            <div class="modal-tool modal-right" @mousedown="dragDown($event, 'right')"></div>

            <div class="modal-tool modal-top-right" @mousedown="dragDown($event, 'top-right')"></div>
            <div class="modal-tool modal-top-left" @mousedown="dragDown($event, 'top-left')"></div>
            <div class="modal-tool modal-bottom-left" @mousedown="dragDown($event, 'bottom-left')"></div>
            <div class="modal-tool modal-bottom-right" @mousedown="dragDown($event, 'bottom-right')"></div>
        </div>
    </div>
</template>

<script>
import Gikam from '../../core/gikam-core.js';
import jQuery from 'jquery';

export default {
    props: {
        options: Object
    },

    data() {
        return {
            state: 'normal',
            Gikam: Gikam,
            initSize: void 0,
            showCloseBtn: this.options.showCloseBtn === false ? false : true,
            showMaxBtn: this.options.showMaxBtn === false ? false : true,
            width: this.getSize(this.options.width, 'width'),
            height: this.getSize(this.options.height, 'height'),
            initX: null,
            initY: null,
            top: null,
            left: null,
            resizing: false,
            direction: null,
            headerCenter: '',
            isModal: Gikam.isEmpty(this.options.isModal) ? true : this.options.isModal,
            screenWidth: self.innerWidth,
            screenHeight: self.innerHeight,
            widthRatio: this.getSize(this.options.width, 'width') / self.innerWidth,
            heightRatio: this.getSize(this.options.height, 'height') / self.innerHeight
        };
    },

    computed: {
        classObject() {
            return [this.state, this.isModal ? 'modal' : 'not-modal'];
        }
    },

    mounted() {
        this.initNotModalPostion();
        this.onResizeModal();
    },

    methods: {
        setHeaderCenterDom(htmlString) {
            this.headerCenter = htmlString;
        },

        close(data) {
            const closeHandle = () => {
                Gikam.removeDom(this.$el);
                this.options.modal.trigger('afterClose', data);
                this.$emit('close');
            };
            setTimeout(() => {
                const result = this.options.modal.trigger('beforeClose', data);
                if (Gikam.isBoolean(result) || result === undefined) {
                    if (result || result === undefined) {
                        closeHandle();
                    }
                } else {
                    result.done(res => {
                        if (res === true) {
                            closeHandle();
                        }
                    });
                }
            });
        },

        //获取iframe元素对象
        getIframeDom() {
            if (this.options.src) return this.$refs.iframe;
        },

        getSize(arg, type) {
            if (Gikam.isNumber(arg)) {
                return parseInt(arg);
            }

            if (Gikam.isEmpty(arg)) {
                arg = '80%';
            }
            const percent = arg.slice(0, arg.length - 1);
            if (type === 'width') {
                const windowWidth = self.innerWidth;
                return parseInt((windowWidth / 100) * Number(percent));
            }

            if (type === 'height') {
                const windowHeight = self.innerHeight;
                return parseInt((windowHeight / 100) * Number(percent));
            }
        },

        propI18N(text) {
            return Gikam.propI18N(text);
        },

        max() {
            let $modal = jQuery(this.$el).children('.modal-container');
            if (!this.left || !this.top) {
                const modalPosition = this.$refs.modalContainer.getBoundingClientRect();
                !this.left && (this.left = modalPosition.left);
                !this.top && (this.top = modalPosition.top);
            }
            this.initSize = {
                height: $modal.css('height'),
                width: $modal.css('width'),
                top: this.top,
                left: this.left
            };
            this.state = 'max';
            $modal.animate(
                {
                    height: '100%',
                    width: '100%',
                    left: null,
                    top: null
                },
                100
            );
            this.top = 0;
            this.left = 0;
            setTimeout(() => {
                this.options.onResize && this.options.onResize();
                this.$emit('resizeMax');
            }, 100);
        },

        restore() {
            this.state = 'normal';
            jQuery(this.$el)
                .children('.modal-container')
                .animate(this.initSize, 100);
            this.top = this.initSize.top;
            this.left = this.initSize.left;
            setTimeout(() => {
                this.options.onResize && this.options.onResize();
                this.$emit('resizeRestore');
            }, 100);
        },

        dragDown(e, direction) {
            if (this.$el.classList.contains('max')) {
                return;
            }
            this.initX = e.pageX;
            this.initY = e.pageY;
            this.resizing = true;
            this.direction = direction;
            document.addEventListener('mousemove', this.dragMove, false);
            document.addEventListener('mouseup', this.dragUp, false);
        },

        onTriggerResizeHandle() {
            Gikam.jQuery(window).trigger('resize');
        },

        dragHandle(distanceX, distanceY) {
            const moveCallback = {
                right: () => {
                    if (distanceX > 0 || (distanceX < 0 && this.width > 250)) {
                        this.width += distanceX;
                    }
                },
                left: () => {
                    if (distanceX < 0 || (distanceX > 0 && this.width > 250)) {
                        this.width -= distanceX;
                        this.left += distanceX;
                    }
                },
                top: () => {
                    if ((distanceY < 0 && this.top > 0) || (distanceY > 0 && this.height > 150)) {
                        this.height -= distanceY;
                        this.top += distanceY;
                    }
                },
                bottom: () => {
                    if (distanceY > 0 || (distanceY < 0 && this.height > 150)) {
                        this.height += distanceY;
                    }
                }
            };

            this.direction === 'right' && moveCallback.right();
            this.direction === 'left' && moveCallback.left();
            this.direction === 'top' && moveCallback.top();
            this.direction === 'bottom' && moveCallback.bottom();
            this.direction === 'top-right' && (moveCallback.top(), moveCallback.right());
            this.direction === 'top-left' && (moveCallback.top(), moveCallback.left());
            this.direction === 'bottom-right' && (moveCallback.bottom(), moveCallback.right());
            this.direction === 'bottom-left' && (moveCallback.bottom(), moveCallback.left());
        },

        moveHandle(distanceX, distanceY) {
            const innerHeight =
                window.innerHeight - this.$refs.modalContainer.querySelector('.modal-header').offsetHeight;
            if ((distanceY > 0 && this.top < innerHeight) || (distanceY < 0 && this.top > 1)) {
                const top = this.top + distanceY;
                this.top = top > 1 ? (top > innerHeight ? innerHeight : top) : 1;
            }
            this.left += distanceX;
        },

        modalReactHandle() {
            const modalPosition = this.$refs.modalContainer.getBoundingClientRect();
            !this.left && (this.left = modalPosition.left);
            !this.top && (this.top = modalPosition.top);
            !this.width && (this.width = modalPosition.width);
            !this.height && (this.height = modalPosition.height);
        },

        dragMove(e) {
            if (!this.resizing) return;
            const distanceX = e.pageX - this.initX;
            const distanceY = e.pageY - this.initY;
            if (Math.abs(distanceX) < 3 && Math.abs(distanceY) < 3) return;
            this.modalReactHandle();
            this.dragHandle(distanceX, distanceY);

            !this.direction && this.moveHandle(distanceX, distanceY);

            this.initX = e.pageX;
            this.initY = e.pageY;
        },

        dragUp() {
            this.initX = null;
            this.initY = null;
            this.resizing = false;
            document.removeEventListener('mousemove', this.dragMove, false);
            document.removeEventListener('mouseup', this.dragUp, false);
            this.$emit('resize');
            this.options.onResize && this.options.onResize();
        },

        //如果是非模态窗口，需要初始化时设置尺寸和位置
        initNotModalPostion() {
            if (this.options.isModal) {
                return;
            }
            const bodyPosition = document.body.getBoundingClientRect();
            const { width, height } = this.options;
            if (width) {
                this.width = Gikam.isString(width) ? bodyPosition.width * (parseInt(width) / 100) : width;
            }
            if (height) {
                this.height = Gikam.isString(height) ? bodyPosition.height * (parseInt(height) / 100) : height;
            }
            this.left = (bodyPosition.width - this.width) / 2;
            this.top = (bodyPosition.height - this.height) / 2;
        },

        //根据浏览器大小，弹窗自适应
        onResizeModal() {
            window.onresize = () => {
                return (() => {
                    window.screenWidth = self.innerWidth;
                    this.screenWidth = window.screenWidth;
                    window.screenHeight = self.innerHeight;
                    this.screenHeight = window.screenHeight;
                    let $modal = jQuery(this.$el).children('.modal-container');
                    $modal.animate(
                        {
                            height: this.screenHeight * this.heightRatio || this.options.height,
                            width: this.screenWidth * this.widthRatio || this.options.width,
                            left: null,
                            top: null
                        },
                        100
                    );
                    setTimeout(() => {
                        this.options.onResize && this.options.onResize();
                        this.$emit('resizeRestore');
                    }, 100);
                })();
            };
        }
    },

    watch: {
        screenWidth(valWidth) {
            this.screenWidth = valWidth;
        },
        screenHeight(valHeight) {
            this.screenHeight = valHeight;
        }
    }
};
</script>

<style scoped>
.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Microsoft YaHei', serif;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal.not-modal {
    width: 0;
    height: 0;
}

.modal.not-modal > .modal-container {
    position: fixed;
}

.modal.normal .restore {
    display: none;
}

.modal.max .max {
    display: none;
}

.modal.max .modal-tool {
    display: none;
}

.modal > .modal-container {
    position: relative;
    width: 80%;
    height: 80%;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.modal > .modal-container.no-user-select {
    user-select: none;
}

.modal > .modal-container > .modal-header {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 0 16px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
    cursor: all-scroll;
}

.modal.max > .modal-container > .modal-header {
    cursor: default;
}

.modal > .modal-container > .modal-header > div {
    z-index: 2;
}

.modal > .modal-container > .modal-header > .modal-title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.modal > .modal-container > .modal-header .header-center {
    width: 100%;
    height: 32px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
}

.modal > .modal-container > .modal-header > .modal-toolbar {
    display: flex;
    align-items: center;
}

.modal > .modal-container > .modal-header button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.5);
    width: 24px;
}

.modal > .modal-container > .modal-body {
    flex: 1;
    overflow: hidden;
    height: 0;
    position: relative;
}

.modal > .modal-container > .modal-body > .moving {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.modal > .modal-container > .modal-body > iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.modal > .modal-container > .modal-tool {
    position: absolute;
    z-index: 2;
}

.modal > .modal-container > .modal-tool.modal-top {
    width: 100%;
    height: 4px;
    top: 0;
    left: 0;
    cursor: ns-resize;
}

.modal > .modal-container > .modal-tool.modal-bottom {
    width: 100%;
    height: 4px;
    bottom: 0;
    left: 0;
    cursor: ns-resize;
}

.modal > .modal-container > .modal-tool.modal-left {
    height: 100%;
    width: 4px;
    left: 0;
    top: 0;
    cursor: ew-resize;
}

.modal > .modal-container > .modal-tool.modal-right {
    height: 100%;
    width: 4px;
    right: 0;
    top: 0;
    cursor: ew-resize;
}

.modal > .modal-container > .modal-top-right,
.modal > .modal-container > .modal-top-left,
.modal > .modal-container > .modal-bottom-right,
.modal > .modal-container > .modal-bottom-left {
    width: 8px;
    height: 8px;
}

.modal > .modal-container > .modal-top-right {
    top: 0;
    right: 0;
    cursor: sw-resize;
}

.modal > .modal-container > .modal-top-left {
    top: 0;
    left: 0;
    cursor: nw-resize;
}

.modal > .modal-container > .modal-bottom-right {
    bottom: 0;
    right: 0;
    cursor: nw-resize;
}

.modal > .modal-container > .modal-bottom-left {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
}
</style>
