<template>
    <ul :class="{ 'darg-ul': tree.options.draggable }">
        <li
            v-for="(node, index) in nodeList"
            class="node"
            :key="node.id"
            :class="[
                node.childQty == 0 ? 'no-child' : spreadChildMenu(node) ? 'expanded' : 'collapsed',
                { 'open-li': openTypeHandle(node) },
                filterNodes(index),
                level == 1 ? 'root-node' : '',
                tree.options.selectedNodeData == node ? 'selected' : ''
            ]"
            :id="tree.options.id + '-' + node.id"
            :level="level"
        >
            <div
                class="next-darg prev-darg"
                v-if="tree.options.draggable && index === 0"
                :class="{ 'drag-next-select': dragDropId === node.id && dragDropType === 'prev' }"
                :style="{ paddingLeft: 10 + (level - 1) * 20 + 'px' }"
                @dragenter="allowEnter(node, 'prev', $event)"
                @dragover="allowDrop(node, 'prev', $event)"
                @dragleave="dragleave($event)"
                @drop="drop(node, 'prev', index, $event)"
            >
                <div class="round"></div>
                <div class="line"></div>
            </div>
            <div
                class="next-darg"
                v-if="tree.options.draggable"
                :class="{
                    'drag-next-select': dragDropId === node.id && dragDropType === 'next',
                    open: node.expanded && node.children && node.children.length > 0
                }"
                :style="{ paddingLeft: 10 + (level - 1) * 20 + 'px' }"
                @dragenter="allowEnter(node, 'next', $event)"
                @dragover="allowDrop(node, 'next', $event)"
                @dragleave="dragleave($event)"
                @drop="drop(node, 'next', index, $event)"
            >
                <div class="round"></div>
                <div class="line"></div>
            </div>
            <div
                class="node-desc"
                :class="{ 'drag-select': dragDropId === node.id && dragDropType === 'in' }"
                @mouseenter="mouseenterHandle($event, node)"
                @mouseleave="mouseleaveHandle($event, node)"
                :draggable="tree.options.draggable && !node.editable"
                @dragstart="drag(node, $event)"
                @dragenter="allowEnter(node, 'in', $event)"
                @dragover="allowDrop(node, 'in', $event)"
                @dragleave="dragleave($event)"
                @drop="drop(node, 'in', index, $event)"
                @click="selectNode($event, node)"
            >
                <span class="blank-container">
                    <div
                        class="blank"
                        :class="({ 'last-second': index === level - 2 }, filterBlank(index))"
                        v-for="index in level - 1"
                        :value="index"
                        :key="index"
                    ></div>
                </span>
                <span class="noSwitch" v-if="node.childQty == 0">
                    <fileImg :width="'13px'" :height="'12px'" :color="'#636363'" />
                </span>
                <span class="switch" v-else @click.stop="toggleNode($event, node)">
                    <closeNodeImg
                        class="closeNodeImg"
                        :width="'12px'"
                        :height="'12px'"
                        :color="'#636363'"
                        v-if="spreadChildMenu(node)"
                    />
                    <openNodeImg class="openNodeImg" :width="'12px'" :height="'12px'" :color="'#636363'" v-else />
                </span>

                <div v-if="checkbox" class="tree-checkbox">
                    <checkbox
                        :propChecked="node.checked"
                        :propHalfChecked="getHalfCheck(node)"
                        :propValue="node.id"
                        @change="groupRowCheckedChange"
                        :key="node.id + index"
                    ></checkbox>
                </div>
                <img v-if="showIcon(node.icon)" class="treeIcon" :src="getIcon(node.icon)" />
                <template v-if="node.editable">
                    <input v-model="node.text" />
                    <div class="edit-toolbars">
                        <button class="confirm" @click.stop="confirm(node)" :title="$i18n('tree.confirm')">
                            <tick color="#007aff" />
                        </button>
                        <button class="cancel" @click.stop="cancel(node)" :title="$i18n('tree.cancel')">
                            <shutdownImg color="#007aff" width="10px" height="10px" />
                        </button>
                    </div>
                </template>
                <template v-else>
                    <div class="node-text" @click.stop="selectNodeHandle($event, node)" :title="getTitle(node.text)"
                        v-html="node.text"></div>
                    <div class="node-markerNum" v-if="node.markerNum">
                        <span>{{ node.markerNum }}</span>
                    </div>
                    <edit-toolbar v-if="node.toolbar" @click="clickHandle" :options="node"></edit-toolbar>
                </template>
            </div>
            <tree
                v-if="spreadChildMenu(node)"
                :propOptions="node"
                :propNodeList="node.children"
                :propCheckbox="checkbox"
                :propStringId="propStringId + node.id + ','"
                :propLevel="level + 1"
                :parentNode="node"
                :isLastNodes="lastNodes(index, level, node)"
            ></tree>
        </li>
    </ul>
</template>
<script>
import Vue from 'vue';
import Gikam from 'gikam';
import editToolbar from './editToolbar';
import jQuery from 'jquery';

export default {
    name: 'tree',

    inject: ['tree'],
    props: {
        propOptions: Object,
        propNodeList: Array,
        propCheckbox: Boolean,
        propStringId: String,
        propLevel: Number,
        isLastNodes: Array,
        parentNode: {
            type: Object,
            default: () => null
        }
    },

    data() {
        return {
            selected: false,
            oldNodeText: null,
            nodeList: this.propNodeList,
            checkbox: this.propCheckbox,
            stringId: this.propStringId,
            level: this.propLevel,
            dragDropId: void 0,
            dragDropType: void 0, // 拖动状态，是拖成子级还是兄弟
            isExpanded: [],
            expandes: []
        };
    },

    components: { editToolbar },

    watch: {
        selected(val) {
            if (val === true) {
                let selectedNode = this.tree.options.selectedNode;
                if (selectedNode) {
                    selectedNode.selected = false;
                    selectedNode = null;
                }
                this.tree.options.selectedNode = this;
                this.tree.trigger('nodeSelected', this.data);
            }
        },

        propNodeList(value) {
            this.nodeList = value;
        }
    },

    methods: {
        // 是否展开下级
        spreadChildMenu(node) {
            return node.children && node.children.length > 0 && (node.expanded || this.expandes.includes(node.id));
        },

        filterNodes(index) {
            if (index == this.nodeList.length - 1) {
                return 'last-node';
            } else {
                return 'tree-node';
            }
        },

        filterBlank(index) {
            if (index == 0 || this.isLastNodes[index - 1] == 0) {
                return 'tree-child-blank';
            } else if (index == this.level - 1) {
                return 'last-child-blank';
            } else if (this.isLastNodes[index - 1] == 1) {
                return 'empty-blank';
            }
        },

        lastNodes(index, level, node) {
            let isLast;
            if (index != this.nodeList.length - 1 || (node.children && node.children.length > 1)) {
                isLast = 0;
            } else {
                isLast = 1;
            }
            if (level == this.isLastNodes.length + 1) {
                this.isLastNodes.push(isLast);
            }
            return this.isLastNodes;
        },

        toggleNode(e, node) {
            if (this.tree.options.loading === true) {
                return;
            }
            if (!node.id) {
                return;
            }
            const def = jQuery.Deferred();
            let $node = jQuery(e.target).closest('.node');
            let toggle = function() {
                let $node = jQuery(e.target).closest('.node');
                const $children = $node.children('ul');
                if ($children.length > 0) {
                    $children.slideToggle('fast', function() {
                        $node.toggleClass('expanded collapsed');
                        def.resolve();
                    });
                } else {
                    $node.toggleClass('expanded collapsed');
                    def.resolve();
                }
            };
            const _this = this;
            if (Gikam.isEmpty(node.children) && this.tree.options.async === true) {
                this.tree.getData(node.id).done(nodes => {
                    Vue.set(node, 'children', nodes);
                    _this.$nextTick(() => {
                        toggle();
                        def.done(() => {
                            if ($node.hasClass('expanded')) {
                                this.$set(node, 'expanded', true);
                                this.expandes.push(node.id);
                            } else {
                                this.$set(node, 'expanded', false);
                                this.expandes = this.expandes.filter(item => {
                                    return item !== node.id;
                                });
                            }
                        });
                    });
                });
            } else {
                toggle();
                def.done(() => {
                    if ($node.hasClass('expanded')) {
                        this.$set(node, 'expanded', true);
                        this.expandes.push(node.id);
                    } else {
                        this.$set(node, 'expanded', false);
                        this.expandes = this.expandes.filter(item => {
                            return item !== node.id;
                        });
                    }
                });
            }
            return def;
        },

        getTreeNode(id) {
            return this.nodeList.filter(checkedItem => {
                return checkedItem.id === id;
            })[0];
        },

        allChildCheckedChange(checked, node) {
            if (Gikam.isNotEmpty(node.childQty) && node.childQty != 0) {
                node.children.forEach(item => {
                    this.$set(item, 'checked', checked);
                    this.allChildCheckedChange(checked, item);
                });
            }
        },

        changeParentNodeChecked(node) {
            if (Gikam.isNotEmpty(node.parentId)) {
                let parentNode = this.getParentNode(node.parentId);
                let checkNum = this.getCheckNum(parentNode);
                if (parentNode.childQty != 0 && checkNum == 0) {
                    this.$set(parentNode, 'checked', false);
                    this.changeParentNodeChecked(parentNode);
                } else if (parentNode.childQty != 0 && checkNum != 0) {
                    this.$set(parentNode, 'checked', true);
                    this.changeParentNodeChecked(parentNode);
                }
            }
        },

        getParentNode(parentId) {
            return Gikam.filterTreeList(this.tree.options.data, item => {
                return item.id == parentId;
            })[0];
        },

        groupRowCheckedChange(checked, value, e) {
            e.stopPropagation();
            let node = this.getTreeNode(value);

            if (checked) {
                this.$set(node, 'checked', true);
                this.allChildCheckedChange(true, node);
            } else {
                this.$set(node, 'checked', false);
                this.allChildCheckedChange(false, node);
            }
            if (node.parentId) {
                this.changeParentNodeChecked(node);
            }
        },

        getCheckNum(node) {
            let checkNum = 0;
            node &&
                node.childQty != 0 &&
                node.children.forEach(item => {
                    if (item.checked) {
                        checkNum++;
                    }
                });
            return checkNum;
        },

        getHalfCheckNum(node) {
            let halfCheckNum = 0;
            node &&
                node.childQty != 0 &&
                node.children.forEach(item => {
                    if (this.getHalfCheck(item)) {
                        halfCheckNum++;
                    }
                });
            if (node.childQty == 0) halfCheckNum = 1;
            return halfCheckNum;
        },

        getHalfCheck(node) {
            let num;
            if (Gikam.isNotEmpty(node.childQty) && node.childQty != 0) {
                num = node.children.length;
            } else {
                num = 0;
            }
            let checkNum = this.getCheckNum(node);
            if (num == 0 || (checkNum == num && this.getHalfCheckNum(node) == 0)) {
                return false;
            } else {
                return true;
            }
        },

        selectNodeHandle(e, node) {
            this.selectNode(e, node);
            if (this.tree.options.expandOnTextClick) {
                this.toggleNodeHandle(e);
            }
        },

        // 点击文字 展开树节点
        toggleNodeHandle(e) {
            Gikam.jQuery(e.target)
                .siblings('.switch')
                .click();
        },

        selectNode(e, node) {
            if (this.tree.options.checkOnActive) {
                if (node.checked) {
                    this.groupRowCheckedChange(false, node.id, e);
                } else {
                    this.groupRowCheckedChange(true, node.id, e);
                }
            }

            this.tree.options.selectedNode = jQuery(e.target).closest('.node')[0];
            this.tree.options.selectedNodeData = node;
            this.tree.trigger('nodeSelected', node.id, node);
        },

        mouseleaveHandle(event, node) {
            if (node.toolbar) {
                node.toolbar = false;
                this.$forceUpdate();
            }
        },

        mouseenterHandle(event, node) {
            if (this.tree.editing === true) {
                return;
            }
            if (this.tree.options.edit === false) {
                return;
            }
            node.toolbar = true;
            this.$forceUpdate();
        },

        clickHandle(type, e, node) {
            this[type + 'Node'](type, e, node);
        },

        insertNode(type, event, node) {
            let insert = () => {
                let newNode = {
                    text: '新建节点',
                    id: null,
                    parentId: node.id,
                    editable: true,
                    childQty: 0,
                    children: []
                };
                node.expanded = true;
                !node.children && (node.children = []);
                node.children.push(newNode);
                this.tree.editing = true;
                node.toolbar = false;
            };
            node.childQty++;
            if (
                jQuery(event.target)
                    .closest('.node')
                    .hasClass('expanded')
            ) {
                insert();
            } else {
                this.toggleNode(event, node).done(() => {
                    insert();
                });
            }
        },

        editNode(type, event, node) {
            Vue.set(node, 'editable', true);
            this.tree.editing = true;
            this.oldNodeText = node.text;
            node.toolbar = false;
        },

        removeNode(type, event, node) {
            Gikam.confirm('确认', `确认删除: ${node.text} ?`, () => {
                //树加载为同步模式时，删除节点不再查询是否存在子节点
                if (Gikam.isEmpty(node.children) || this.tree.options.async === false) {
                    !node.children && (node.children = []);
                    this.doDeleteNode(node);
                } else {
                    this.tree.getData(node.id).done(data => {
                        Vue.set(node, 'children', data);
                        this.doDeleteNode(node);
                    });
                }
            });
        },

        doDeleteNode(node) {
            let window = Gikam.getInstance(jQuery(this.$el).closest('.window'));
            let _this = this;
            if (this.tree.trigger('beforeDelete', node.id) === false) {
                return;
            }
            if (Gikam.isNotEmpty(node.children)) {
                Gikam.alert('GIKAM.TIP.TREE.CHILDREN-EXISTS');
                return;
            }
            let json = {
                id: node.id,
                version: node.version
            };
            window.showMask();
            Gikam.del(this.tree.options.baseUrl, Gikam.getJsonWrapper(null, [null, [json]]))
                .done(function() {
                    _this.tree.deleteNode(node);
                })
                .always(function() {
                    if (
                        (!Gikam.isEmpty(_this.propOptions.children) && _this.propOptions.children.length == 0) ||
                        _this.propOptions.children === undefined
                    ) {
                        _this.propOptions.childQty = 0;
                    }
                    _this.parentNode && _this.parentNode.childQty > 0 && (_this.parentNode.childQty -= 1);
                    window.closeMask();
                });
        },

        confirm(node) {
            // 判断输入的树节点值是否为空。
            if (Gikam.isEmpty(node.text)) {
                return Gikam.alert('节点名称不能为空！');
            }
            if (this.tree.trigger('beforeSave', node) === false) {
                return;
            }
            if (!this.propOptions.childQty && this.nodeList.length > 0) {
                this.propOptions.childQty = 1;
                this.propOptions.expanded = true;
            }
            node.editable = false;
            node.text = node.text.trim();
            let window = Gikam.getInstance(jQuery(this.$el).closest('.window'));
            let json;
            let _this = this;
            if (node.id) {
                json = {
                    id: node.id,
                    version: node.version
                };
            } else {
                json = {
                    parentId: node.parentId
                };
            }
            json[this.tree.options.nodeTextField] = node.text;
            this.tree.trigger('beforeRequest', json);
            window.showMask();
            Gikam[node.id ? 'put' : 'postText'](
                Gikam.printf(this.tree.options.baseUrl + (node.id ? '/{id}' : ''), {
                    id: node.id || '',
                    version: node.version
                }),
                Gikam.getJsonWrapper(null, [null, [json]])
            )
                .then(function(id) {
                    return Gikam.getJson(_this.tree.options.baseUrl + '/' + (id || node.id));
                })
                .done(function(newNode) {
                    node.id = newNode.id;
                    node.version = newNode.version;
                    _this.tree.trigger('afterEdit', node.id);
                })
                .always(function() {
                    _this.tree.editing = false;
                    node.toolbar = false;
                    window.closeMask();
                });
        },

        cancel(node) {
            node.editable = false;
            this.tree.editing = false;
            if (!node.id) {
                this.tree.deleteNode(node);
                if (
                    (this.propOptions.children && this.propOptions.children.length == 0) ||
                    this.propOptions.children === undefined
                ) {
                    this.propOptions.childQty = 0;
                }
            } else {
                node.text = this.oldNodeText;
            }
            node.toolbar = false;
        },

        // 是否显示 横杠
        openTypeHandle(node) {
            return (
                node.expanded &&
                node.children &&
                node.children.length > 0 &&
                this.tree.model.treeStringId &&
                this.tree.model.treeStringId.indexOf(node.id) > -1
            );
        },

        // 拖动的方法-拖
        drag(node, e) {
            e.stopPropagation();
            e.dataTransfer.setData('Text', JSON.stringify(node));
            this.dragNodeId = node.id;
        },

        // 进入-某个拖动单位-触发一次
        allowEnter(node, type, e) {
            let dom = Gikam.jQuery(e.currentTarget).find('.switch');
            this.toggleChildrenNode && clearTimeout(this.toggleChildrenNode);
            this.toggleChildrenNode = null;
            if (this.dragNodeId !== node.id && !node.expanded && type === 'in') {
                this.toggleChildrenNode = setTimeout(() => {
                    dom.click();
                }, 1300);
            }
        },

        // 进入-某个拖动单位
        allowDrop(node, type, e) {
            e.preventDefault();
            e.stopPropagation();
            this.dragDropId = node.id;
            this.dragDropType = type;

            type === 'in' && (this.tree.model.treeStringId = this.stringId + node.id);
        },

        // 离开-某个拖动单位
        dragleave(e) {
            e.preventDefault();
            e.stopPropagation();
            this.dragDropId = void 0;
            this.dragDropType = void 0;
        },

        // 保存拖的节点数据
        dragDataFn(e) {
            let dragNode = e.dataTransfer.getData('Text');
            return JSON.parse(dragNode);
        },

        // 父节点不能拖到自己的子节点
        checkChildNode(list, nodeId) {
            for (let i = 0; i < list.length; i++) {
                if (list[i].id === nodeId) {
                    return false;
                }
                if (list[i].children) {
                    if (this.checkChildNode(list[i].children, nodeId) === false) {
                        return false;
                    }
                }
            }
            return true;
        },

        // 获取其改变位置后的上一个兄弟节点
        getPreNode(node, type) {
            let preNode = node;
            if (type === 'prev') {
                preNode = null;
            }
            if (type === 'in') {
                if (!node.children) {
                    preNode = null;
                } else {
                    preNode = node.children[node.children.length - 1];
                }
            }
            return preNode;
        },

        // 拖动的方法-落
        drop(node, type, index, e) {
            e.preventDefault();
            e.stopPropagation();
            this.dragDropId = void 0;
            this.dragDropType = void 0;

            this.toggleChildrenNode && clearTimeout(this.toggleChildrenNode);
            this.toggleChildrenNode = null;
            // 获取其改变位置后的直接父节点，若没有传null
            let parentNode = this.parentNode;
            type === 'in' && (parentNode = node);
            // 获取其改变位置后的上一个兄弟节点
            let preNode = this.getPreNode(node, type);

            // 判断-父级不能拖到自己的子级
            let dragData = this.dragDataFn(e);
            if (node.id === dragData.id) {
                return false;
            }
            if (dragData.children) {
                if (!this.checkChildNode(dragData.children, node.id)) {
                    return;
                }
            }

            this.deleteChildrenNode(this.tree.options.data, e);
            this.delEmptyChildren(this.tree.options.data);

            // 判断是变成其子节点还是兄弟节点
            if (type === 'prev') {
                this.nodeList.unshift(dragData);
            } else if (type === 'next') {
                this.nextNode(dragData, index);
            } else {
                this.$nextTick(() => {
                    if (node.children) {
                        node.children.push(dragData);
                    } else {
                        node.children = [dragData];
                    }
                });
            }
            this.$nextTick(() => {
                // 参数：1.自身（拖）2.直接父级3.上一个4.放下（放）5.全部
                this.tree.trigger('afterDrop', dragData, parentNode, preNode, node, this.tree.options.data);
                this.setChildQty();
            });
            e.dataTransfer.setData('Text', void 0);
            this.tree.model.treeStringId = '';
        },

        // 同级组件拖放
        nextNode(dragData, index) {
            this.nodeList.splice(index + 1, 0, dragData);
        },

        // 拖拽完成删除 初始节点【重要】
        deleteChildrenNode(node, e) {
            let dragData = this.dragDataFn(e);
            for (let i = 0; i < node.length; i++) {
                if (node[i].id === dragData.id) {
                    node.splice(i, 1);
                    break;
                }
                if (node[i].children) {
                    this.deleteChildrenNode(node[i].children, e);
                }
            }
        },

        // 当children数据长度为0时，删除children属性
        delEmptyChildren(node) {
            node.forEach(item => {
                if (item.children) {
                    if (item.children.length === 0) {
                        delete item.children;
                    } else {
                        this.delEmptyChildren(item.children);
                    }
                }
                this.$nextTick(() => {
                    item.childQty = item.children ? item.children.length : 0;
                });
            });
        },

        // 设置childQty的数值
        setChildQty() {
            if (this.tree.options.async) {
                return;
            }
            this.nodeList.forEach(item => {
                this.$set(item, 'childQty', item.children ? item.children.length : 0);
            });
        },

        showIcon(icon) {
            if (icon && icon.indexOf('/') != -1) {
                return true;
            } else {
                return false;
            }
        },

        getIcon(icon) {
            return Gikam.IFM_CONTEXT + icon;
        },

        getTitle(text) {
            return text.replace(/<\/?.+?>/g,"").replace(/ /g,"");
        }
    }
};
</script>

<style scoped>
ul {
    list-style-type: none;
    padding: 0;
    position: relative;
    margin: 0;
}

ul.darg-ul {
    padding: 8px 0;
}

ul.darg-ul ul {
    padding: 0;
}

.node > .next-darg {
    width: 100%;
    height: 6px;
    border-radius: 2px;
    z-index: 8;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: -3px;
}

.node.last-node > .next-darg {
    position: absolute;
    left: 0;
    bottom: 0px;
}

.node > .next-darg.open {
    position: absolute;
    left: 0;
    bottom: 0;
}

.node > .next-darg.prev-darg {
    position: absolute;
    left: 0;
    top: -3px;
}

.node > .next-darg > .round {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.node > .next-darg.drag-next-select > .round {
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid rgba(0, 122, 255, 0.5);
}

.node > .next-darg > .line {
    flex: 1;
    height: 2px;
}

.node > .next-darg.drag-next-select > .line {
    background-color: rgba(0, 122, 255, 0.5);
}

.switch {
    width: 12px;
    position: relative;
}

.noSwitch {
    position: relative;
}

.node.no-child > .node-desc > .noSwitch {
    display: inline-block;
    width: 12px;
}

.node > .node-desc > .treeIcon {
    width: 12px;
    height: 12px;
    margin-left: 6px;
    object-fit: cover;
    text-align: center;
}

.node-text {
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    margin-left: 5px;
    padding: 0;
    cursor: pointer;
    height: 28px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.node-markerNum {
    color: #fff;
    margin: 0 2px;
}

.node-markerNum > span {
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    background-color: red;
    padding: 3px 8px;
    border-radius: 10px;
}

.node {
    line-height: 28px;
    position: relative;
}

ul.darg-ul .node.open-li {
    padding-bottom: 6px;
}

.node .node-desc:hover {
    background-color: #d9d9d9;
}

.node .node-desc {
    border: 1px solid transparent;
}

.node .node-desc.drag-select {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.node.selected > .node-desc {
    background-color: rgba(0, 122, 255, 0.1);
}

.blank::before {
    border-left: 1px dotted #ccc;
    content: '';
    display: block;
    position: absolute;
    left: 5px;
    right: 0;
    top: -22px;
    bottom: -10px;
}

.node .node-desc .tree-checkbox {
    margin-left: 6px;
}

.node > ul > li:not(:last-child):not(:first-child) > .node-desc > .switch::before {
    border-left: 1px dotted #ccc;
    content: '';
    display: block;
    position: absolute;
    left: 5px;
    right: 0;
    top: -5px;
    bottom: 20px;
}

.node > ul > li:not(:last-child):not(:first-child) > .node-desc > .noSwitch::before {
    border-left: 1px dotted #ccc;
    content: '';
    display: block;
    position: absolute;
    left: 5px;
    right: 0;
    top: -5px;
    bottom: 20px;
}

.node > ul > li:last-child:not(:first-child) > .node-desc > .switch::before,
.node > ul > li:last-child:not(:first-child) > .node-desc > .noSwitch::before {
    border-left: 1px dotted #ccc;
    content: '';
    display: block;
    position: absolute;
    left: 5px;
    right: 0;
    top: -5px;
    bottom: 20px;
}

.node > ul > li:first-child:not(:last-child) > .node-desc > .switch::before,
.node > ul > li:first-child:not(:last-child) > .node-desc > .noSwitch::before {
    border-left: 1px dotted #ccc;
    content: '';
    display: block;
    position: absolute;
    left: 5px;
    right: 0;
    top: 20px;
    bottom: 10px;
}

.last-node > .node-desc > .blank-container > .last-child-blank::before {
    border-left: 1px dotted #ccc;
    content: '';
    display: block;
    position: absolute;
    left: 5px;
    right: 0;
    top: -15px;
    bottom: 0;
}

.tree-node > .node-desc > .blank-container > .last-child-blank::before {
    border-left: 1px dotted #ccc;
    content: '';
    display: block;
    position: absolute;
    left: 5px;
    right: 0;
    top: -22px;
    bottom: -10px;
}

.last-node > ul > li:last-child > .node-desc > .blank-container > .blank:last-child:before {
    border-left: 1px dotted #ccc;
    content: '';
    display: block;
    position: absolute;
    left: 5px;
    right: 0;
    top: -17px;
    bottom: 0;
}

.last-node > ul > .last-node > ul > li > .node-desc > .blank-container > .blank:nth-last-child(2)::before {
    border: 0;
}

.tree-node > ul > li:last-child > .node-desc > .blank-container > .blank:last-child:before {
    border-left: 1px dotted #ccc;
    content: '';
    display: block;
    position: absolute;
    left: 5px;
    right: 0;
    top: -22px;
    bottom: -10px;
}

.tree-child-blank::before {
    border-left: 1px dotted #ccc;
    content: '';
    display: block;
    position: absolute;
    left: 5px;
    right: 0;
    top: -22px;
    bottom: -10px;
}

.empty-blank::before {
    border: 0;
}

.node:last-child::before {
    bottom: 14px;
}

.node.no-child.only-node > .node-desc > .blank-container > .blank:last-child:before {
    border-left: 1px dotted #ccc;
    content: '';
    display: block;
    position: absolute;
    left: 5px;
    right: 0;
    top: -15px;
    bottom: 0;
}

.root-node.last-node > ul > li.last-node .blank:first-child:before {
    border: 0;
}

.root-node:not(:first-child) > .node-desc > .blank-container::before {
    border-left: 1px dotted #ccc;
    content: '';
    display: block;
    width: 11px;
    position: absolute;
    left: 13px;
    right: 0;
    top: -5px;
    bottom: 20px;
}

.blank-container {
    display: flex;
}

.blank {
    width: 20px;
    position: relative;
}

.blank.last-second:before {
    border-left: 1px dotted #ccc;
    content: '';
    display: block;
    position: absolute;
    left: 5px;
    right: 0;
    top: -22px;
    bottom: -10px;
}

.blank:last-child:after {
    content: '';
    display: block;
    position: absolute;
    left: 7px;
    right: 0;
    top: -20px;
    bottom: 0;
    border-bottom: 1px dotted #ccc;
}

.no-child .blank:last-child:after {
    content: '';
    display: block;
    position: absolute;
    left: 7px;
    right: 0;
    top: -20px;
    bottom: 0;
    border-bottom: 1px dotted #ccc;
}

ul .node-desc {
    height: 28px;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
}

ul li.collapsed ul {
    display: none;
}

ul .node-desc .switch {
    cursor: pointer;
    padding: 0;
}

ul li > .node-desc input {
    margin-left: 8px;
    width: 100px;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    height: 22px;
    font-size: 12px;
    padding-left: 5px;
    margin-right: 5px;
    color: #666;
}

ul li > .node-desc input:focus {
    border: 1px solid #4784ff;
}

ul li > .node-desc .edit-toolbars button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

ul li > .node-desc .edit-toolbars .cancel {
    width: 22px;
}

ul li > .node-desc .edit-toolbars .confirm {
    width: 26px;
}
</style>
