<template>
    <div class="header-container">
        <table
            :style="{ width: width + 'px', left: scrollLeft }"
            :class="{ 'filter-open': filterOpen && filter, 'no-user-select': columnResizing }"
            @keydown.tab.stop.prevent
        >
            <thead>
                <tr>
                    <template v-for="(item, index) in columns">
                        <th
                            v-if="getFieldVisible(item) && getFieldHideHeader(item)"
                            :key="getHeaderKey(item, index)"
                            :width="item.width"
                            :colspan="item.colspan"
                            :class="{ columnsBoder: index === columnsSelectIndex, resizing: resizing === item.field }"
                            @contextmenu.prevent="showGeneralButtonGroup(item, index, $event)"
                        >
                            <div v-if="propEditorInvisible && item.editor" class="editor-flag"></div>
                            <template v-if="item.checkbox || item.radio">
                                <div class="corner-marker" v-if="propShowCheckedNum && propTotalChecked.isShow">
                                    {{ propTotalChecked.num }}
                                </div>
                                <div v-if="item.checkbox" class="all-checkbox-container">
                                    <checkbox @change="checkHandle" :propChecked="allChecked"></checkbox>
                                </div>
                                <div class="filter" v-if="filter" v-show="filterOpen">
                                    <div class="container">
                                        <div class="icon" @click="closeFilter">
                                            <cleanImg width="12px" height="12px" />
                                        </div>
                                        <div class="icon" @click="resetFilter">
                                            <clearImg width="12px" height="12px" color="#F9762B" />
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="item.index === true">
                                <div class="index-header-cell">{{ $i18n('grid.indexHeaderCell') }}</div>
                            </template>
                            <template v-else>
                                <i
                                    class="grid-header-resize"
                                    @mousedown="dragDown($event, item)"
                                    v-if="propColumnResize"
                                ></i>
                                <div
                                    :class="{ 'is-sort': item.sort !== false }"
                                    :style="{ textAlign: item.titleAlign }"
                                    @click="sort($event, item, index)"
                                >
                                    <span
                                        :class="item | getLabelClass"
                                        v-html="(propI18N(item.title) + '').replace(/<\/?p>/g, '')"
                                        :title="formatTitleTip(item)"
                                    ></span>
                                </div>
                                <div
                                    class="filter"
                                    v-show="filter && filterOpen"
                                    :style="{ visibility: propFixedColumn ? 'hidden' : 'visible' }"
                                    @keyup.enter="changeFilter"
                                >
                                    <div v-if="item.filter === false"></div>
                                    <editor
                                        v-else
                                        :options="item"
                                        :filterOriginalData="filterOriginalData"
                                        :filter="headerFilter"
                                        :ref="item.field"
                                    ></editor>
                                </div>
                                <i
                                    class="grid-mobile-resize"
                                    @touchstart="dragDown($event, item)"
                                    v-show="showMobileResize(item)"
                                ></i>
                                <div
                                    class="exchange-wrapper"
                                    v-if="showSortArrow && orderField === item.field && orderType"
                                >
                                    <div class="exchange-up" :class="{ active: orderType === 'asc' }"></div>
                                    <div class="exchange-down" :class="{ active: orderType === 'desc' }"></div>
                                </div>
                            </template>
                        </th>
                    </template>
                </tr>
            </thead>
        </table>
    </div>
</template>

<script>
import Gikam from '../../../core/gikam-core';
import Vue from 'vue';
import filterDateField from './filterDateField.vue';
import filterDateTimeField from './filterDateTimeField.vue';
import { mapState } from 'vuex';

Vue.component('filterDateField', filterDateField);
Vue.component('filterDateTimeField', filterDateTimeField);

// eslint-disable-next-line
const getEditorType = (options, fieldType) => {
    let type = options.type || 'text';
    if (type === 'link' || type === 'choose' || type === 'tree' || type === 'bulkUploads') {
        type = 'text';
    } else if (type === 'simpleCheckbox') {
        type = 'select';
        options.items = [
            { text: '', value: '' },
            { text: '是', value: '1' },
            { text: '否', value: '0' }
        ];
    }

    if (fieldType === 'dateTime') {
        type = 'filterDateTime';
    } else if (fieldType === 'date') {
        type = 'filterDate';
    } else if (fieldType === 'number') {
        type = 'number';
    }

    if (options.type === 'select') {
        type = 'select';
    }
    return type;
};

const getEnterType = ({ type = 'text' }) => {
    let _type = type;
    if (type === 'dateTime') {
        _type = 'filterDateTime';
    } else if (type === 'date') {
        _type = 'filterDate';
    }
    return _type;
};

const getFieldMatch = (ctx, options) => {
    const fieldTypeMapper = ctx.parent.$store.state.fieldTypeMapper;
    const type = fieldTypeMapper ? fieldTypeMapper[options.field.toLowerCase().replace('ext$.', '')] : 'string';
    let matchType = 'SC';
    if (options.type === 'select' && options.multiple === true) {
        matchType = 'OR';
    } else if (options.type === 'select' || options.type === 'simpleCheckbox') {
        matchType = 'EQ';
    } else {
        matchType = {
            string: 'CISC',
            number: 'NEQ',
            data: 'DEQ',
            dateTime: 'TEQ'
        }[type];
    }
    if (!options.field.includes('_') || (options.field.includes('$') && !options.field.includes('ext$.'))) {
        return options.field + '_' + matchType;
    } else {
        return options.field;
    }
};

// 通过表头快捷数据刷新表格
function refreshByHeaderData(context, fieldOptions, value) {
    const store = context.parent.$store;
    const fieldMatch = getFieldMatch(context, fieldOptions);
    store.commit('updateHeadRequestData', { [fieldMatch]: value });
    store.commit('refresh');
}

// 判断是否服务器端查询
function isFieldSeverSearch(context, fieldOptions) {
    return !Gikam.isFalse(context.injections.grid.options.serverSearch) && !Gikam.isFalse(fieldOptions.serverSearch);
}

const editor = {
    functional: true,
    inject: ['grid'],
    // eslint-disable-next-line complexity
    render(h, context) {
        const options = Gikam.deepExtend(context.props.options);
        options.readonly = false;
        delete options.validators;
        if (options.type === 'select') {
            options.search = true;
            options.placeholder = options.placeholder || ' ';
        }
        options.type === 'richText' && delete options.type;
        options.type === 'textarea' && delete options.type;
        options._isHeader = true;

        const fieldTypeMapper = context.parent.$store.state.fieldTypeMapper;
        let fieldType = null;
        if (options.field && fieldTypeMapper) {
            const simpleFieldName = context.props.options.field.toLowerCase().replace('ext$.', '');
            fieldType = fieldTypeMapper[simpleFieldName];
        }
        options.field && options.fieldType && (fieldType = options.fieldType);
        const type = options.filter?.type ? getEnterType(options.filter) : getEditorType(options, fieldType);

        let editor = type + 'Field';
        const readonly = options.filter === false ? true : false;
        return (
            <editor
                ref={options.field}
                options={options}
                propReadonly={readonly}
                propSetValueAfterChoose={true}
                propValue={options.field ? context.parent.requestFieldData[options.field.replace('ext$.', '')] : null}
                onChange={(...args) => {
                    context.props.filterOriginalData[options.field] = args[1];
                    if (options.filter === false || context.props.filter === false) {
                        return;
                    }
                    context.parent.$store.commit('updatePageNum', 1);
                    if (isFieldSeverSearch(context, options)) {
                        refreshByHeaderData(context, options, args[1]);
                    } else {
                        const store = context.parent.$store;
                        const _options = Gikam.deepExtend(options, { type: editor });
                        store.commit('pageSearch', { args, column: _options });
                    }
                }}
                onBlur={(...args) => {
                    if (isFieldSeverSearch(context, options) && !options.url) {
                        refreshByHeaderData(context, options, args[1]);
                    }
                }}
                onInput={event => {
                    Gikam.finalDelay(
                        'GridSearch',
                        () => {
                            const grid = context.injections?.grid;
                            if (grid && grid.options?.filterImmediate) {
                                const value = event.target?.value;
                                if (isFieldSeverSearch(context, options) && !options.url) {
                                    refreshByHeaderData(context, options, value);
                                }
                            }
                        },
                        400
                    );
                }}
            ></editor>
        );
    }
};

export default {
    props: {
        propFilter: Boolean,
        propFilterOpen: Boolean,
        propColumnResize: Boolean,
        propEditorInvisible: Boolean,
        allChecked: {
            type: Boolean,
            default: false
        },
        propTotalChecked: {
            isShow: Boolean,
            num: Number
        },
        propShowCheckedNum: Boolean,
        propFixedColumn: {
            type: Boolean,
            default: false
        },
        showSortArrow: Boolean,
        propOrderField: String,
        propOrderType: String
    },

    components: { editor },

    inject: ['grid'],

    data() {
        return {
            filterOriginalData: {}, // 快捷查询的原始条件
            initX: null,
            resizeCell: null,
            columnResizing: false,
            orderField: void 0,
            orderType: void 0,
            // 移动端拖动改变列宽的标识
            showResizeField: [],
            resizing: false,

            headerFilter: true
        };
    },

    filters: {
        getLabelClass(field) {
            const isNotEmpty = field.validators && field.validators.indexOf('notEmpty') >= 0 ? true : false;
            if (isNotEmpty && field.titleHighlightNotEmpty) {
                return 'titleHighlight';
            } else if (isNotEmpty) {
                return 'hasRedStar';
            } else {
                return '';
            }
        }
    },

    computed: {
        ...mapState('chooseColumns', ['columnsSelectIndex']),

        columns() {
            return this.$store.state.columns;
        },
        fields() {
            return this.columns.map(item => {
                if (item.field) {
                    return item.field;
                }
                return '';
            });
        },

        width() {
            return this.$store.state.width;
        },

        scrollLeft() {
            return -this.$store.state.scrollLeft + 'px';
        },

        filter() {
            return this.propFilter;
        },

        filterOpen() {
            return this.propFilterOpen;
        },

        requestData() {
            return this.$store.state.requestData;
        },

        //将条件转换为仅有field的映射
        requestFieldData() {
            const headRequestData = this.$store.state.headRequestData;
            const data = {};
            for (let field in headRequestData) {
                data[field.split('_')[0]] = headRequestData[field];
            }
            return data;
        }
    },

    methods: {
        showMobileResize(item) {
            return item.field && this.showResizeField.indexOf(item.field) > -1;
        },

        //右键表头展开操作弹窗事件
        showGeneralButtonGroup(field, index, event) {
            if (!field.generalButtonGroup) {
                return;
            }
            //不可编辑列阻止赋值
            if (!this.columns[index] || !this.columns[index].editor) {
                return;
            }
            const _this = this;
            new Gikam.Vue({
                el: Gikam.createDom('div', document.body),
                render() {
                    return (
                        <header-button-group
                            ref="vm"
                            target={event.target}
                            index={index}
                            buttonArray={field.generalButtonGroup}
                            ondownFillValueEvent={_this.downFillValueEvent}
                        ></header-button-group>
                    );
                },
                components: {
                    headerButtonGroup: () => import('./headerButtonGroup.vue')
                }
            });
        },

        //向下赋值按钮事件
        downFillValueEvent(index) {
            const field = this.columns[index].field;
            this.$emit('downFillValueEvent', { index, field });
        },

        propI18N(text) {
            return Gikam.propI18N(text);
        },

        checkHandle(checked) {
            this.$emit('allCheck', checked);
        },

        closeFilter() {
            this.$emit('filterClose');
        },

        changeFilter(e) {
            if (e) {
                e.target.blur && e.target.blur();
            }
        },

        resetFilter() {
            this.$emit('allCheck', false);
            for (let name in this.$refs) {
                this.$refs[name].value = '';
            }
        },

        dragDown(e, item) {
            this.initX = e.pageX || e.touches[0].pageX;
            this.columnResizing = true;
            this.resizeCell = item;
            this.resizeCell.width = parseInt(this.resizeCell.width);
            if (!this.resizeCell.width || this.resizeCell.width <= 0) {
                this.resizeCell.width = e.target.parentNode.offsetWidth;
            }
            this.grid.gridMask && (this.resizing = this.resizeCell.field);
            document.addEventListener('mousemove', this.dragMove, false);
            document.addEventListener('mouseup', this.dragUp, false);
            document.addEventListener('touchmove', this.dragMove, false);
            document.addEventListener('touchend', this.dragUp, false);
        },

        dragMove(e) {
            if (!this.columnResizing) return;
            const pageX = e.pageX || e.touches[0].pageX;
            let distance = pageX - this.initX;
            if (Math.abs(distance) < 3) return;
            if (distance < 0 && this.resizeCell.width < 30) return;
            distance && (this.resizeCell.width += distance);
            this.$store.commit('changeColumnsWidth', this.width + distance);
            this.initX = pageX;
        },

        dragUp() {
            this.initX = null;
            this.columnResizing = false;
            this.resizeCell = null;
            this.grid.gridMask && (this.resizing = false);
            document.removeEventListener('mousemove', this.dragMove, false);
            document.removeEventListener('mouseup', this.dragUp, false);
            document.removeEventListener('touchmove', this.dragMove, false);
            document.removeEventListener('touchend', this.dragUp, false);
            Gikam.finalDelay('saveColumns', this.saveColumns, 500);
        },

        saveColumns() {
            this.grid.trigger('columnsWidthChange', this.columns);
            if (Gikam.isEmpty(this.grid.options.id)) {
                return;
            }
            const url = Gikam.IFM_CONTEXT + '/core/module/sys/page-grid-field-configs';
            const { gridId, $window } = this.$store.state;
            const columnsConfig = this.columns
                .filter(item => !item.checkbox && item.index !== true && !item.radio)
                .map(item => {
                    return {
                        field: item.field,
                        width: parseInt(item.width),
                        visible: item.visible === '0' ? '0' : '1',
                        fixed: item.fixed == true ? '1' : '0'
                    };
                });
            return Gikam.postText(
                url,
                Gikam.getJsonWrapper(
                    {
                        gridId,
                        pageId: $window.$pageId
                    },
                    ['', columnsConfig]
                )
            );
        },

        mobileColumnResize(index) {
            if (index === this.fields.length) {
                return;
            }
            this.showResizeField = [];
            this.fields[index] && this.showResizeField.push(this.fields[index]);
            this.fields[index - 1] && this.showResizeField.push(this.fields[index - 1]);
        },

        updateResizeField(flag) {
            this.showResizeField = [];
            if (flag) {
                for (let i = 0; i < this.fields.length; i++) {
                    if (this.fields[i]) {
                        this.showResizeField.push(this.fields[i]);
                        break;
                    }
                }
            }
        },

        // 排序
        sort(e, item, index) {
            if (this.grid.gridMask && Gikam.isMobile()) {
                this.mobileColumnResize(index);
                return;
            }
            if (this.grid.options.columnsSelect && this.columnsSelectIndex !== index) {
                this.$store.commit('chooseColumns/changeColumnsSelectIndex', index);
                this.grid.trigger('columnActive', item.field, index);
                return;
            }
            if (item.sort === false) {
                return;
            }
            if (this.orderField === item.field) {
                if (!this.orderType) {
                    this.orderType = 'asc';
                } else {
                    this.orderType = { asc: 'desc', desc: void 0 }[this.orderType];
                }
            } else {
                this.orderField = item.field;
                this.orderType = 'asc';
            }
            this.$emit('sortListGrid', this.orderField, this.orderType);
        },

        getHeaderKey(options, index) {
            if (options.field) {
                return options.field + index;
            }
            if (options.index) {
                return 'index' + new Date();
            }
            if (options.checkbox) {
                return 'checkbox' + new Date();
            }
            if (options.radio) {
                return 'radio' + new Date();
            }
            return options.title + index;
        },

        getFieldVisible(options) {
            if (options.visible === undefined || options.visible === '1') {
                return true;
            }
            return false;
        },

        // 获取表头隐藏属性hideHeader
        getFieldHideHeader(field) {
            if (field.hideHeader) {
                return false;
            }
            return true;
        },

        getCellWidth(item) {
            return item.width ? 'width:' + (item.width + 'px') : null;
        },

        formatTitleTip(item) {
            const title = item.titleTip ? item.titleTip : item.title;
            return (this.propI18N(title) + '').replace(/<[^>]*>|<\/[^>]*>/gm, ' ');
        }
    },

    watch: {
        propOrderField(val) {
            this.orderField = val;
        },

        propOrderType(val) {
            this.orderType = val;
        }
    }
};
</script>

<style scoped>
.header-container {
    margin-top: 5px;
    border: 1px solid #eee;
    border-bottom: none;
    box-shadow: 0 2px 3.6px 0.4px rgba(0, 0, 0, 0.06);
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    position: relative;
    table-layout: fixed;
}

table > thead > tr > th {
    position: relative;
    height: 35px;
    padding: 0 8px;
    font-weight: normal;
    text-align: center;
    border-right: 1px solid #eee;
}

table > thead > tr > th > div {
    overflow: hidden;
}

table > thead > tr > th > div span {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

table > thead > tr > th > div span.titleHighlight,
table > thead > tr > th > div span.hasRedStar {
    position: relative;
    padding-right: 8px;
}

table > thead > tr > th > div span.titleHighlight {
    color: #ff3b30;
}

table > thead > tr > th > div span.titleHighlight::before,
table > thead > tr > th > div span.hasRedStar::before {
    content: '*';
    color: #ff3b30;
    position: absolute;
    right: 0;
    top: -3px;
}

table.filter-open > thead > tr > th {
    height: 66px;
    vertical-align: top;
    padding-top: 9px;
}

table > thead > tr > th > .all-checkbox-container,
table > thead > tr > th > .index-header-cell {
    display: flex;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

table > thead > tr > th > .corner-marker {
    font-size: 12px;
    color: #ffffff;
    letter-spacing: 0;
    padding: 0 4px;
    height: 14px;
    line-height: 14px;
    position: absolute;
    top: 0;
    right: 0;
    background: #ff003c;
    border-radius: 7px;
}

table > thead {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid #eee;
}

table > thead > tr > th > .grid-header-resize {
    position: absolute;
    width: 6px;
    top: 0;
    bottom: 0;
    right: -3px;
    z-index: 1;
    cursor: ew-resize;
}

table > thead > tr > th > .grid-mobile-resize {
    position: absolute;
    width: 3px;
    right: -2px;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: #007aff;
}

table > thead > tr > th > .grid-mobile-resize::before {
    position: absolute;
    content: '';
    width: 13px;
    height: 13px;
    border-radius: 50%;
    top: -6px;
    left: -5px;
    background-color: #007aff;
}

table > thead > tr > th:last-child > .grid-header-resize {
    right: 0;
}

.columns-fill table > thead > tr > th:last-child > .grid-header-resize,
.columns-fill table > thead > tr > th:last-child > .grid-mobile-resize {
    display: none;
}

.columns-fill table > thead > tr > th:last-child {
    border-right: none;
}

table > thead > tr > th .is-sort {
    padding-right: 8px;
    text-overflow: ellipsis;
    cursor: pointer;
}

table > thead > tr > th > .sort {
    position: absolute;
    top: 3px;
    right: 0;
    width: 19px;
    height: 29px;
    padding-right: 8px;
    padding-left: 3px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.no-user-select {
    user-select: none;
}

.filter {
    padding: 9px 0 4px 0;
}

.filter > * {
    height: 24px;
}

.filter > .container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter > .container > .icon {
    cursor: pointer;
}

.filter > .container > .icon:nth-child(1) {
    margin-right: 8px;
}

.editor-flag {
    position: absolute;
    top: 0;
    border-style: solid;
    left: 0;
    border-width: 4px;
    border-color: #007aff transparent transparent #007aff;
}

table > thead > tr > th.resizing {
    background-color: rgba(0, 122, 255, 0.24);
}

/* 边框 */
.noDataHeader table > thead > tr > th.columnsBoder {
    border-bottom: 2px solid #007aff;
}
table > thead > tr > th:nth-last-child(1).columnsBoder {
    border-right: 1px solid transparent;
}
table > thead > tr > th.columnsBoder {
    position: relative;
    border-top: 2px solid #007aff;
    border-radius: 2px;
}
table > thead > tr > th.columnsBoder::before {
    content: '';
    position: absolute;
    border-left: 2px solid #007aff;
    left: -1px;
    top: 0;
    bottom: -1px;
    overflow: hidden;
}
table > thead > tr > th.columnsBoder::after {
    content: '';
    position: absolute;
    border-left: 2px solid #007aff;
    right: -1px;
    top: 0;
    bottom: -1px;
    overflow: hidden;
}

.exchange-wrapper {
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -7px;
    width: 10px;
    height: 14px;
}

.exchange-wrapper > div {
    width: 0;
    height: 0;
    cursor: pointer;
}

.exchange-wrapper > .exchange-up {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 5px solid #d9d9d9;
    margin-bottom: 2px;
}

.exchange-wrapper > .exchange-up.active {
    border-bottom-color: #007aff;
}

.exchange-wrapper > .exchange-down {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid #d9d9d9;
}

.exchange-wrapper > .exchange-down.active {
    border-top-color: #007aff;
}
</style>
