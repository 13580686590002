<template>
    <div class="grid grid-fixed" :style="{ width: columnWidth + 'px', top: top + 'px', bottom }" v-show="showFixedGrid">
        <div class="grid-header">
            <grid-header
                ref="header"
                :propColumns="propOptions.columns"
                :propFill="options.fill"
                :propWidth="columnWidth"
                :propFilter="options.filter"
                :propFilterOpen="options.filterOpen"
                :propColumnResize="options.columnResize"
                :propEditorInvisible="options.editorInvisible"
                :allChecked="allChecked"
                :propFixedColumn="true"
                :propTotalChecked="propTotalChecked"
                :propShowCheckedNum="propShowCheckedNum"
                :showSortArrow="options.showSortArrow"
                :propOrderField="propOrderField"
                :propOrderType="propOrderType"
                @allCheck="
                    allChecked => {
                        this.allChecked = allChecked;
                        this.isAllChecked(allChecked);
                        this.checkboxClickFn();
                    }
                "
                @filterClose="
                    () => {
                        this.grid.options.filterOpen = false;
                    }
                "
                @sortListGrid="sortGrid"
            ></grid-header>
        </div>

        <div class="grid-body" v-show="options.showBody" ref="vs">
            <grid-fixed-top
                :options="options"
                :fixedIndex="rowFixedTop"
                v-if="showRowFixedTop"
                :propCheckeds="checkeds"
                :rowActiveId="rowActiveId"
                :propColumns="propOptions.columns"
                :propWidth="columnWidth"
                @checkedsHandle="checkedsHandle"
            />
            <component
                :is="scroll"
                barSize="0"
                ref="fixedVs"
                :scrollingY="false"
                :scrollingX="false"
                :scrollTransition="transition"
            >
                <table :style="{ width: columnWidth }" @touchstart.stop @touchmove.stop @touchend.stop>
                    <thead>
                        <template v-for="(item, index) in propOptions.columns">
                            <th :key="index" :style="getCellWidth(item)" v-if="getFieldVisible(item)"></th>
                        </template>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(row, rowIndex) in fixedData"
                            :key="row.id || rowIndex"
                            @click="rowActive(rowIndex)"
                            @mouseup="checkboxClickFn(row)"
                            :data-index="rowIndex"
                            :row-group-index="row._groupIndex"
                            :parent-group-index="row._parentGroupIndex"
                            :class="{
                                expand: row._expand,
                                'row-group': row._groupRow,
                                hidden: row._hidden,
                                hide: row.show === false,
                                active: rowActiveId === row.id
                            }"
                            :ref="'row-' + row.id"
                            :style="getRowStyle(row)"
                        >
                            <td
                                v-if="row._groupRow"
                                class="body-cell body-cell-group"
                                :colspan="propOptions.columns.length"
                            >
                                <div class="switch" style="cursor: default;">
                                    <roundAddImg
                                        v-if="!row._expand"
                                        v-bind="{ color: '#007aff', width: '16px', height: '16px' }"
                                    />
                                    <roundMinusImg
                                        v-if="row._expand"
                                        v-bind="{ color: '#007aff', width: '16px', height: '16px' }"
                                    />
                                </div>
                                <div v-html="getRowGroupFormatter(row)"></div>
                            </td>
                            <template v-else v-for="(field, cellIndex) in propOptions.columns">
                                <td
                                    v-if="getFieldVisible(field)"
                                    :style="cellStyleFormatter(field, row)"
                                    :key="cellIndex"
                                    @dblclick="td_dbClick(row, rowIndex, field)"
                                    @click="cellClick(row, rowIndex, field)"
                                >
                                    <template v-if="field.editor && renderEditor(field, row)">
                                        <div class="body-cell">
                                            <cellEditor
                                                :options="field"
                                                :row="row"
                                                :rowIndex="rowIndex"
                                                :cellIndex="cellIndex"
                                                :ref="rowIndex + '_' + field.field"
                                            />
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div v-if="field.checkbox" class="body-cell select-row-checkbox-cell">
                                            <checkbox
                                                :ref="'checkbox_' + rowIndex"
                                                :propChecked="
                                                    allChecked || row._checked || checkboxTypeHandle(rowIndex)
                                                "
                                                :propValue="rowIndex"
                                                @change="rowCheckedChange"
                                                :key="row.id + rowIndex"
                                                v-if="options.type !== 'treeGrid'"
                                            ></checkbox>
                                        </div>
                                        <div v-else-if="field.radio" class="body-cell select-row-radio-cell">
                                            <radio
                                                :ref="'checkbox_' + rowIndex"
                                                :propChecked="allChecked || row._checked"
                                                :propValue="rowIndex"
                                                @change="rowRadioChange"
                                                v-if="options.type !== 'treeGrid'"
                                            ></radio>
                                        </div>
                                        <div v-else-if="field.index === true" class="body-cell row-index-cell">
                                            {{ row.gridIndex || rowIndex + 1 }}
                                        </div>
                                        <div v-else-if="field.type === 'link'" class="body-cell">
                                            <a href="javascript:" v-html="getFieldValue(row, field.field)"></a>
                                        </div>
                                        <!--下拉框类型显示值-->
                                        <div v-else-if="field.type === 'select'" class="body-cell">
                                            <div
                                                class="readonly-text"
                                                v-html="getSelectText(field, row, rowIndex)"
                                            ></div>
                                        </div>
                                        <!--checkbox类型显示值-->
                                        <div v-else-if="field.type === 'checkbox'" class="body-cell">
                                            <div
                                                class="readonly-text"
                                                v-html="getCheckboxText(field, row, rowIndex)"
                                            ></div>
                                        </div>
                                        <div v-else-if="field.type === 'richText'" class="body-cell">
                                            <richTextField
                                                class="readonly-text"
                                                :propReadonly="true"
                                                :options="{ field: field }"
                                                :propValue="row[field.field]"
                                                :rowIndex="rowIndex"
                                            ></richTextField>
                                        </div>
                                        <!-- 电子签名 -->
                                        <div v-else-if="field.type === 'sign'" class="body-cell">
                                            <signField
                                                :propValue="getFieldValue(row, field.field)"
                                                :options="{ field: field }"
                                                :propReadonly="true"
                                            ></signField>
                                        </div>
                                        <!--simpleCheckbox-->
                                        <div v-else-if="field.type === 'simpleCheckbox'" class="body-cell">
                                            <simple-checkbox-field
                                                :propValue="getFieldValue(row, field.field)"
                                                :options="{ field: field }"
                                                :propReadonly="true"
                                            />
                                        </div>
                                        <!--treeGrid字段类型-->
                                        <div v-else-if="field.type === 'tree'" class="body-cell">
                                            <treeGridNode
                                                :ref="'node_' + rowIndex"
                                                v-bind="{
                                                    row,
                                                    single: options.single,
                                                    fieldOptions: field,
                                                    rowIndex,
                                                    checkedNodes
                                                }"
                                                @change="nodeClickHandle"
                                                v-if="options.type === 'treeGrid'"
                                            ></treeGridNode>
                                        </div>
                                        <div v-else class="body-cell" :field="field.field">
                                            <div
                                                class="cell-icon"
                                                v-if="field.iconFormatter"
                                                :class="field.iconFormatter(row)"
                                            ></div>
                                            <cellTagField
                                                v-else-if="field.tagFormatter"
                                                :options="field.tagFormatter(row)"
                                            ></cellTagField>
                                            <div
                                                v-else
                                                class="readonly-text"
                                                :title="getReadonlyTitle(rowIndex, field, row)"
                                                v-html="getReadonlyText(rowIndex, field, row)"
                                            ></div>
                                        </div>
                                    </template>
                                </td>
                            </template>
                        </tr>
                        <tr v-if="(!fixedData || fixedData.length === 0) && loading === false">
                            <td class="no-record-cell" :colspan="propOptions.columns.length">
                                <div class="no-record body-cell">
                                    <div class="no-record-div">
                                        <img class="no-record-img" src="../../../../img/no-record.png" />
                                        <span class="no-record-text">{{ $i18n('grid.fixedNoRecordText') }}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </component>
        </div>
    </div>
</template>

<script>
import Gikam from '../../../core/gikam-core';
import genericQueryPanel from './gridGeneralQueryPanel.vue';
import gridHeader from './fixedHeader';
import treeGridNode from './treeGridNode.vue';
import Vue from 'vue';
import jQuery from 'jquery';
import cellEditor from './cellEditor';
import DefaultScroll from '../../template/scroll/vue/defaultScroll.vue';
import GridFixedTop from './gridFixedTop.vue';
export default {
    props: {
        options: Object,
        propFilterOpen: Boolean,
        rowActiveId: [String, Number],
        propCheckeds: Array,
        propTotalChecked: {
            isShow: Boolean,
            num: Number
        },
        propShowCheckedNum: Boolean,
        transition: Boolean,
        showFixedColumns: String,
        rowFixedTop: Array,
        showRowFixedTop: Boolean,
        propOrderType: String,
        propOrderField: String
    },

    inject: {
        grid: {
            default: null
        }
    },

    computed: {
        height() {
            if (this.options.fill) {
                return '100%';
            }
            const height = this.options.height;
            return Gikam.isNumber(height) ? height + 'px' : height;
        },

        width() {
            return this.$store.state.width + 'px';
        },

        maxRow() {
            return this.options.data.length - 1;
        },

        maxCol() {
            return this.options.columns.length - 1;
        },

        cellLineFeed() {
            return this.options.textLineFeed;
        },

        allColumns() {
            return this.$store.state.columns;
        },

        fixedData() {
            return this.options.data;
        },

        scrollTop() {
            return this.$store.state.scrollTop;
        },

        scrollLeft() {
            return this.$store.state.scrollLeft;
        }
    },

    provide() {
        return {
            checkedNodes: this.checkedNodes
        };
    },

    methods: {
        // 全选事件
        isAllChecked(allChecked) {
            !allChecked && (this.checkeds = []);
        },

        // checkbox通过index判断是否选中
        checkboxTypeHandle(index) {
            if (this.checkeds.includes(index)) return true;
            return false;
        },

        checkedsHandle(data) {
            this.checkeds = data;
            this.allCheckboxType();
        },

        // 全选状态自动改变
        allCheckboxType() {
            if (this.checkeds.length !== 0 && this.checkeds.length === this.options.data.length) {
                this.allChecked = true;
            } else {
                this.allChecked = false;
            }
        },

        getColumnTitle(field) {
            return Gikam.propI18N(field.title);
        },

        getRowGroupFormatter(row) {
            return this.options.group.formatter.call(this.grid, row);
        },

        switchRowGroup(row) {
            row._expand = !row._expand;
            this.options.data
                .filter(subRow => {
                    return subRow._parentGroupIndex === row._groupIndex;
                })
                .forEach(item => {
                    item._hidden = !row._expand;
                });
        },

        getReadonlyText(rowIndex, field, row) {
            if (field.formatter) {
                return field.formatter(rowIndex, Gikam.getFieldValue(row, field.field), row);
            } else {
                return Gikam.getFieldValue(row, field.field);
            }
        },

        getReadonlyTitle(rowIndex, field, row) {
            let title = this.getReadonlyText(rowIndex, field, row);
            return Gikam.isNotEmpty(title) ? (title + '').replace(/<\/?[a-zA-Z]+[^><]*>/g, '') : '';
        },

        getFieldValue(data, field) {
            return Gikam.getFieldValue(data, field);
        },

        rowCheckedChange(checked, rowIndex, event,id,triggerBySelf = true) {
            event && event.stopPropagation();
            const row = this.options.data[rowIndex];
            if (checked) {
                this.checkeds.push(rowIndex);
                this.checkeds.sort((a, b) => {
                    return a - b;
                });
                this.grid.trigger('select', row,triggerBySelf);
            } else {
                this.checkeds.splice(this.checkeds.indexOf(rowIndex), 1);
                this.grid.trigger('unSelect', row,triggerBySelf);
            }
            this.checkboxClickFn();
            this.allCheckboxType();
        },

        rowRadioChange(checked, rowIndex, event) {
            let checkedRowIndex = this.checkeds[0];
            if (Gikam.isNotEmpty(checkedRowIndex)) {
                this.$refs['checkbox_' + checkedRowIndex][0].checked = false;
                this.checkeds.length = 0;
            }
            this.rowCheckedChange(checked, rowIndex, event);
        },

        nodeClickHandle(checked, single, row) {
            this.$set(row, 'checked', checked);
            this.grid.trigger('nodeSelected', row, checked);
            if (single) {
                if (checked) {
                    this.options.data
                        .filter(item => item.checked)
                        .forEach(item => {
                            this.$set(item, 'checked', false);
                            this.grid.trigger('nodeSelected', item, false);
                        });
                    this.$set(row, 'checked', true);
                }
            } else {
                const childrenRow = this.getAllChildren(row, []);
                if (Gikam.isNotEmpty(childrenRow)) {
                    childrenRow.forEach(_row => {
                        this.$set(_row, 'checked', checked);
                        this.grid.trigger('nodeSelected', _row, checked);
                    });
                }
            }
        },

        checkboxClickFn() {
            this.$nextTick(() => {
                this.grid.trigger('checkboxClick');
            });
        },

        getSelections() {
            return this.checkeds.map(rowIndex => Gikam.deepExtend(this.options.data[rowIndex]));
        },

        getSelectText(field, item, rowIndex) {
            let selectText = null;
            let value = Gikam.getFieldValue(item, field.field);
            Gikam.each(field.items, function() {
                if (this.value === value) {
                    selectText = this.text;
                    return false;
                }
            });
            return field.formatter ? field.formatter(rowIndex, value, item, selectText) : selectText;
        },

        //td双击事件
        td_dbClick(row, rowIndex, field) {
            const fieldName = field.field;
            this.grid.trigger('cellDbClick', fieldName, row, rowIndex);
        },

        cellClick(row, rowIndex, field) {
            if (field.type === 'link') {
                const window = Gikam.getInstance(jQuery(this.$el).closest('.window'));
                if (window) {
                    window.model.storage = {
                        grid: this.grid,
                        index: rowIndex
                    };
                }
            }
            this.grid.trigger('cellClick', field.field, Gikam.deepExtend(row), rowIndex);
        },

        rowActive(index) {
            this.grid.activeRowByIndex(index);
        },

        getActiveRow() {
            if (this.activeRowData) {
                let row = Gikam.deepExtend(this.activeRowData);
                row.index = ~~this.activeRow.getAttribute('data-index');
                return row;
            } else {
                return null;
            }
        },

        clean() {
            this.checkeds = [];
            this.checkedNodes = [];
            this.activeRow = null;
            this.activeRowData = null;
            this.allChecked = false;
        },

        //上方工具栏查询
        initCompoSearch() {
            if (Gikam.isEmpty(this.options.toolbar)) {
                return;
            }
            let _this = this;
            let formOptions = this.options.toolbar.filter(item => {
                return item.type === 'form';
            })[0];
            if (Gikam.isEmpty(formOptions)) {
                return;
            }
            formOptions.fields.forEach(item => {
                let change = item.onChange;
                item.onChange = function() {
                    _this.grid.refresh({
                        requestData: this.getData()
                    });
                    change && change.call(form);
                };
            });
            let toolbarItem = jQuery('<div class="item"></div>').appendTo(this.$el.querySelector('.search'));
            formOptions.renderTo = toolbarItem[0];
            let form = Gikam.create('form', formOptions);
        },

        setFieldsReadonly(index, fields, isReadonly) {
            for (let key in this.$refs) {
                if (
                    this.$refs[key].options &&
                    fields.indexOf(this.$refs[key].options.field) > -1 &&
                    parseInt(key.split('_')[0]) === index
                ) {
                    this.$refs[key].readonly = isReadonly;
                }
            }
        },

        renderEditor(field, row) {
            if (field.onBeforeEditorRender) {
                return field.onBeforeEditorRender.call(this.grid, row);
            } else {
                return true;
            }
        },

        genericQueryReset() {
            const requestData = this.grid.options.requestData;
            this.options.genericQueryFields.forEach(item => {
                for (let key in requestData) {
                    if (key.indexOf(item.field) >= 0) {
                        delete requestData[key];
                    }
                }
            });
            this.options.genericQueryFields = this.defaultGenericQueryFields;
            this.grid.refresh();
        },

        popGenericQueryPanel() {
            if (this.activeGenericQuery) {
                return;
            }
            this.genericQueryCount = this.options.genericQueryFields.length;
            const $searchBtn = jQuery(this.$el.querySelector('.generic-query-btn'));
            const left = $searchBtn.offset().left + 'px';
            const top = $searchBtn.offset().top + $searchBtn.outerHeight() + 2 + 'px';
            const _this = this;
            this.activeGenericQuery = new Vue({
                el: jQuery(
                    '<generic-query-Panel :options="options" @change="changeHandle" @destroy="destroyHandle"/>'
                ).appendTo('body')[0],
                provide: {
                    grid: this.grid
                },
                components: { genericQueryPanel },
                methods: {
                    changeHandle(conditions) {
                        _this.genericQueryCount = conditions.length;
                    },
                    destroyHandle() {
                        _this.activeGenericQuery = void 0;
                    }
                },
                data() {
                    return {
                        options: {
                            left: left,
                            top: top,
                            fields: _this.options.columns.filter(item => item.field),
                            genericQueryFields: _this.options.genericQueryFields
                        }
                    };
                }
            });
        },

        cellStyleFormatter(fieldOptions, row) {
            if (fieldOptions.styleFormatter) {
                return fieldOptions.styleFormatter(row);
            }
        },

        getRowStyle(row) {
            if (this.options.rowStyleFormatter) {
                return this.options.rowStyleFormatter(row);
            }
        },

        getAllChildren(row, result) {
            const _this = this;
            if (row.children) {
                row.children.forEach(item => {
                    if (item.children) {
                        _this.getAllChildren(item, result);
                    }
                    result.push(item);
                });
            }
            return result;
        },

        inputCursorMovement(rowIndex, columnIndex, direction) {
            //处理边界情况
            if (columnIndex < 0 && rowIndex < 0) {
                this.inputCursorMovement(this.maxRow, this.maxCol, direction);
                return;
            } else if (columnIndex > this.maxCol && rowIndex > this.maxRow) {
                this.inputCursorMovement(0, 0, direction);
                return;
            } else if (columnIndex < 0) {
                this.inputCursorMovement(rowIndex - 1, this.maxCol, direction);
                return;
            } else if (rowIndex < 0) {
                this.inputCursorMovement(this.maxRow, columnIndex - 1, direction);
                return;
            } else if (columnIndex > this.maxCol) {
                this.inputCursorMovement(rowIndex + 1, 0, direction);
                return;
            } else if (rowIndex > this.maxRow) {
                this.inputCursorMovement(0, columnIndex + 1, direction);
                return;
            }

            const nextTextInput = this.$refs[rowIndex + '_' + columnIndex];
            //处理能否获取光标情况
            if (nextTextInput && nextTextInput.$options.name === 'textInput') {
                nextTextInput.focus();
            } else {
                switch (direction) {
                    case 'left':
                        this.inputCursorMovement(rowIndex, columnIndex - 1, direction);
                        break;
                    case 'up':
                        this.inputCursorMovement(rowIndex - 1, columnIndex - 1, direction);
                        break;
                    case 'right':
                        this.inputCursorMovement(rowIndex, columnIndex + 1, direction);
                        break;
                    case 'down':
                        this.inputCursorMovement(rowIndex + 1, columnIndex, direction);
                        break;
                    default:
                        return;
                }
            }
        },

        // 排序
        sortGrid(orderField, orderType) {
            this.$emit('sortListGrid', orderField, orderType);
        },

        getCheckedNodeRow() {
            return this.checkedNodes.map(item => item.row);
        },

        getFieldVisible(options) {
            if (options.visible === undefined || options.visible === '1') {
                return true;
            }
            return false;
        },

        fixColumns(scrollLeft) {
            //需要固定的总宽度
            let width = 0;
            //配置固定列的field
            let columns = [];
            this.fixedWidth = [];
            Gikam.extend(true, this.fixedOptions, this.options);
            this.propOptions.columns = this.allColumns.filter(item => {
                if (item.fixed == true) {
                    columns.push(item.field);
                    let temp = 0;
                    if (this.fixedWidth.length) {
                        temp = this.fixedWidth.reduce((prev, current) => {
                            return prev + current;
                        });
                    }
                    this.fixedWidth.push(width - temp);
                }
                width += parseInt(item.width);
                return item.fixed == true;
            });

            let showNum = 0;
            for (let i = this.fixedWidth.length; i > 0; i--) {
                if (this.fixedWidth[i - 1] < scrollLeft - 1) {
                    showNum = Math.max(showNum, i);
                }
            }

            if (showNum) {
                this.showFixedGrid = true;
            } else {
                this.showFixedGrid = false;
                return;
            }
            this.propOptions.columns = this.propOptions.columns.filter((item, i) => i < showNum);
            this.columnWidth = 0;
            this.propOptions.columns.forEach(item => {
                this.columnWidth += parseInt(item.width);
            });
            const t = Gikam.compConfigManager.compactStyle ? 1 : 7;
            this.top = this.grid.model.$refs.vm.$refs.toolbar.offsetHeight + t;
            this.$nextTick(() => {
                this.$refs.fixedVs.scrollTo({
                    y: this.scrollTop,
                    x: 0
                });
            });
        },

        getCellWidth(item) {
            return item.width ? 'width:' + (item.width + 'px') : null;
        },

        getFixedBottom() {
            const b1 = this.options.scroll === 'visible' ? 53 : 36;
            const bottom = this.options.page ? b1 : this.options.scroll === 'visible' ? 17 : 0;
            const paddingBottom = Gikam.jQuery(this.grid.model.$el).css('padding-bottom');
            this.bottom = paddingBottom ? bottom + parseInt(paddingBottom) + 'px' : bottom + 'px';
        }
    },

    watch: {
        allChecked() {
            if (this.allChecked === true) {
                const checkedIndex = [];
                for (let i = 0; i < this.options.data.length; i++) {
                    checkedIndex.push(i);
                }
                this.checkeds = checkedIndex;
            } else {
                // this.checkeds = [];  // checkbox是否选中通过allChecked || row._checked || checkboxTypeHandle(rowIndex) 判断
            }
            this.grid.trigger('allCheck', this.allChecked);
        },

        scrollLeft(val, oldVal) {
            if (Math.abs(val - oldVal) < 5) return;
            this.fixColumns(val);
        },

        scrollTop(val) {
            this.$refs.fixedVs.scrollTo({ y: val });
        },

        propCheckeds: {
            handler(val) {
                this.checkeds = val;
                this.allCheckboxType();
            },
            deep: true
        },

        checkeds: {
            handler(val) {
                this.$emit('checkedsHandle', val);
            },
            deep: true
        },

        showFixedColumns() {
            this.fixColumns(this.scrollLeft);
            this.fixColumns(this.scrollLeft + 1);
        }
    },

    data() {
        return {
            Gikam: Gikam,
            allChecked: false,
            checkeds: this.propCheckeds,
            checkedNodes: [],
            selectedRow: [],
            loading: false,
            activeRow: null,
            activeRowData: null,
            genericQueryCount: 0,
            activeGenericQuery: void 0,
            defaultGenericQueryFields: Gikam.extend(true, [], this.options.genericQueryFields),
            pageNum: this.options.pageNum,
            headerWidth: this.options.width,
            editorInvisible: this.options.editorInvisible,
            showFixedGrid: false,
            fixedOptions: {},
            propOptions: {
                columns: []
            },
            top: 0,
            columnWidth: 0,
            fixedWidth: [],
            bottom: null,
            scroll: Gikam.isMobile() || this.options.scroll === 'visible' ? 'DefaultScroll' : 'scroll'
        };
    },

    components: {
        DefaultScroll,
        gridHeader,
        treeGridNode,
        cellEditor,
        cellTagField: {
            props: {
                options: {
                    type: Object,
                    default: () => {
                        return {
                            type: '',
                            value: ''
                        };
                    }
                }
            },
            template:
                '<div class="cell-tag" :class="options.type"><div class="cell-tag-text">{{options.value}}</div></div>'
        },
        GridFixedTop
    },

    mounted() {
        this.$nextTick(() => {
            this.getFixedBottom();
        });
    }
};
</script>

<style scoped>
.grid-header {
    background-color: #fff;
}

.grid.grid-fixed {
    left: 8px;
    z-index: 1;
    box-shadow: 6px 2px 4px 0 #fff;
}

.grid.grid-fixed >>> .default-scroll {
    overflow: hidden;
}

.tab .tab-content .grid.grid-fixed {
    left: -1px;
}
</style>
